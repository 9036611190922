import {
    Container, Box, Typography, Button, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TablePagination, IconButton, Alert, Snackbar
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import UserSidebar from '../components/UserSidebar';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postRequest } from '../Api/api';
import { format } from 'date-fns';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TicketForm from '../components/TicketForm';

function CustomerSupport() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [addModal, SetAddModal] = useState(false);
    const [editFormData, setEditFormData] = useState(null);
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setMessage] = useState('');

    const options = [
        { value: "1", label: "Pending", color: "#3023df" },
        { value: "2", label: "Open", color: "green" },
        { value: "3", label: "Resolved", color: "blue" },
        { value: "4", label: "Closed", color: "red" },
        { value: "5", label: "Reopened", color: "green" }
    ];

    const getStatusLabel = (status: any) => {
        const option = options.find(opt => opt.value === status);
        return option ? option.label : '';
    };

    const getStatusColor = (status: any) => {
        const option = options.find(opt => opt.value === status);
        return option ? option.color : 'black';
    };

    const handleActionClick = (record: any) => {
        console.log("handleActionClick :: record :: ", record);
        setEditFormData(record);
        SetAddModal(true);
    };


    const showModal = () => {
        // setEditFormData(null);
        SetAddModal(true);
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchTicket = async () => {
        try {
            const response = await postRequest("get_tickets", navigate, dispatch);
            console.log("fetchTicket :: response :: ", response);
            if (!response.error) {
                setData(response.data);
            }
        } catch (error) {
            console.log("fetchTicket :: error :: ", error);
        }
    }

    const handleClose = () => {
        SetAddModal(false);
    };

    const handleSaveAddress = async (formData: any) => {
        try {
            let details;
            // console.log("handleSaveAddress :: formData :: ", formData);
  
            if (editFormData) {
                details = await postRequest("edit_ticket", navigate, dispatch, formData);
            } else {
                details = await postRequest("add_ticket", navigate, dispatch, formData);
            }
            // console.log("editFormData :: ", details);
            if (details.status === 201 || details.status === 200) {
                SetAddModal(false);
                setOpenSnack(true);
                setMessage(details.message);
                fetchTicket();
            }
        } catch (error) {
            console.log("handleSaveAddress :: error :: ", error);
        }
    };

    const handleSnackClose = () => {
        setOpenSnack(false);
    };

    useEffect(() => {
        fetchTicket();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Container maxWidth="lg">
                <Box display="flex" mt={3}>
                    <UserSidebar />
                    <Box sx={{ flexGrow: 1, p: 2 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant="h5" gutterBottom style={{ paddingTop: "10px", fontWeight: "600" }}>
                                Customer Support
                            </Typography>
                            <Button variant="contained" style={{ backgroundColor: "#00AFCA" }} onClick={showModal}>
                                Create a ticket
                            </Button>
                        </div>
                        <br />

                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Id</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Ticket Type</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Subject</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Status</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Create Date</TableCell>
                                            <TableCell> </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            data.length !== 0 ? (
                                                <>
                                                    {data
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((row: any, index: any) => {
                                                            return (
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                    <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{index + 1}</TableCell>
                                                                    <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.ticket_type}</TableCell>
                                                                    <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.subject}</TableCell>
                                                                    <TableCell
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            fontFamily: '"Poppins", sans-serif',
                                                                            color: getStatusColor(row.status)
                                                                        }}
                                                                    >
                                                                        {getStatusLabel(row.status)}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{format(new Date(row.date_created), 'dd-MM-yyyy h:mm a')}</TableCell>
                                                                    <TableCell>
                                                                        <IconButton aria-label="actions" onClick={(e) => handleActionClick(row)}>
                                                                            <MoreVertIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </>
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={6} style={{ textAlign: 'center', fontSize: "18px", fontFamily: '"Poppins", sans-serif' }}>
                                                        No matching records found
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>

                        <TicketForm open={addModal} onClose={handleClose} onSave={handleSaveAddress} initialFormData={editFormData} />
                    </Box>
                </Box>
            </Container>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleSnackClose} severity={"error"} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>

    )
}

export default CustomerSupport;