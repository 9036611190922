import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../pages/index.css";
import ClearIcon from '@mui/icons-material/Clear';

interface SidebarProps {
    attribute: any[];
    expanded: string | false;
    handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    handleFilterChange: (attributeId: string, valueId: string) => void;
    selectedFilters: { [key: string]: string[] };
    clearFilters: () => void;
    closeDrawer: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ attribute, expanded, handleChange, handleFilterChange, selectedFilters, clearFilters, closeDrawer }) => {
    const isChecked = (attributeId: string, valueId: string) => {
        return selectedFilters[attributeId] ? selectedFilters[attributeId].includes(valueId) : false;
    };

    return (
        <div className="sidebar">
            <div className="filter-container">
                <div className="filter-header">
                    <h3>Filter</h3>
                    <IconButton onClick={clearFilters}>
                        <ClearIcon style={{ color: "#00AFCA" }} />
                    </IconButton>
                </div>
                {attribute.map((e: any, index: number) => (
                    <Accordion
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        key={index}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            sx={{
                                backgroundColor: expanded === `panel${index}` ? "#accff1" : "inherit"
                            }}
                        >
                            {e.name}
                        </AccordionSummary>
                        {e.subList.map((attr: any, i: number) => (
                            <AccordionDetails style={{ padding: "8px 20px" }} key={i}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isChecked(e.id, attr._id)}
                                            onChange={() => {
                                                handleFilterChange(e.id, attr._id)
                                                closeDrawer();
                                            }}
                                        />
                                    }
                                    label={attr.value}
                                    key={i}
                                />
                            </AccordionDetails>
                        ))}
                    </Accordion>
                ))}
            </div>
        </div>
    )
}

export default Sidebar;