import React from 'react';
import { Container, Box, Grid, TextField, Button, Typography, Divider, IconButton } from '@mui/material';
import { Google as GoogleIcon, Facebook as FacebookIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

function Login() {
    return (
        <Container maxWidth="lg" style={{ marginTop: "30px", marginBottom: "30px" }} >
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            p: 4,
                            border: '1px solid #ddd',
                            borderRadius: 2,
                            backgroundColor: '#f8f9fa',
                        }}
                    >
                        <Typography variant="h4" gutterBottom>Login</Typography>
                        <Box component="form">
                            <TextField
                                fullWidth
                                required
                                id="mobile-number"
                                label="Mobile Number"
                                margin="normal"
                                variant="outlined"
                                defaultValue="9876543210"
                            />
                            <TextField
                                fullWidth
                                required
                                id="password"
                                label="Password"
                                type="password"
                                margin="normal"
                                variant="outlined"
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#00AFCA" }}
                                sx={{ mt: 2 }}
                            >
                                Login
                            </Button>
                        </Box>
                        <Typography variant="body2" align="right" sx={{ mt: 2 }}>
                            <Link to={"/"} style={{ textDecoration: 'none', color: '#00AFCA' }}>Forgot Password?</Link>
                        </Typography>
                        <Divider sx={{ my: 2 }}>OR</Divider>
                        <Box sx={{ textAlign: 'center' }}>
                            <IconButton
                                color="error"
                                sx={{ mx: 1 }}
                            >
                                <GoogleIcon />
                            </IconButton>
                            <IconButton
                                style={{ backgroundColor: "#00AFCA" }}
                                sx={{ mx: 1 }}
                            >
                                <FacebookIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            p: 4,
                            border: '1px solid #ddd',
                            borderRadius: 2,
                            backgroundColor: '#f8f9fa',
                        }}
                    >
                        <Typography variant="h4" gutterBottom>REGISTER</Typography>
                        <Typography variant="body1" paragraph>
                            Registering for this site allows you to access your order status and history.
                            Just fill in the fields below, and we'll get a new account set up for you in no time.
                            We will only ask you for information necessary to make the purchase process faster and easier.
                        </Typography>
                        <Button
                            fullWidth
                            variant="contained"
                            style={{ backgroundColor: "#00AFCA" }}
                        >
                            Register
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Login;