import { Container, Box, Typography, Grid, Button, Card, CardActions, CardContent, CardMedia, IconButton, Rating } from '@mui/material';
import UserSidebar from '../components/UserSidebar';
import DeleteIcon from '@mui/icons-material/Delete';
import { postRequest } from '../Api/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

function Wishlist() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [wishlistItems, setWishlistItems] = useState([]);

    const fetchWishlist = async () => {
        try {
            const response = await postRequest("get_favorites", navigate, dispatch);
            console.log("Wishlist :: response :: ", response);
            let products = response.data;

            products.forEach((product: { variants: any[]; minPrice: number; maxPrice: number; }) => {
                let allPrices: number[] = [];
                product.variants.forEach((variant: { price: any; special_price: any; }) => {
                    allPrices.push(Number(variant.price));
                    allPrices.push(Number(variant.special_price));
                });

                // Set min-max price range to the product object
                product.minPrice = Math.min(...allPrices);
                product.maxPrice = Math.max(...allPrices);
            });
            setWishlistItems(response.data);
        } catch (error) {
            console.log("error :: ", error);
        }
    }

    const handleClick = async (e: any) => {
        try {
            console.log("e :: ", e);
            const response = await postRequest("remove_from_favorites", navigate, dispatch, {
                product_id: e.id
            });
            console.log("response :: ", response);
            fetchWishlist();
            window.dispatchEvent(new Event("wishlistUpdated"));
        } catch (error) {
            console.log("error :: ", error);
        }
    }

    useEffect(() => {
        fetchWishlist();
        // eslint-disable-next-line
    }, []);

    return (
        <Container maxWidth="lg">
            <Box display="flex" mt={3}>
                <UserSidebar />
                <Box sx={{ flexGrow: 1, p: 2 }}>
                    <Typography variant="h5" gutterBottom style={{ padding: "10px", fontWeight: "600" }}>
                        Your Wishlist
                    </Typography>
                    {
                        wishlistItems.length !== 0 ? (
                            <Grid container spacing={2}>
                                {wishlistItems.map((item: any) => (
                                    <Grid item xs={12} sm={6} md={4} key={item.id}>
                                        <Card>
                                            <CardMedia
                                                component="img"
                                                height="300"
                                                image={item.image}
                                                alt={item.name}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h6" component="div" fontSize={'large'}>
                                                    {item.name}
                                                </Typography>

                                                <Rating value={Number(item.rating)} readOnly />
                                                <Typography variant="h6" color="#00AFCA" fontSize={'medium'}>
                                                    ₹{item.minPrice}{" "}-{" "}₹{item.maxPrice}
                                                </Typography>
                                            </CardContent>
                                            <CardActions style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Button size="small" variant="contained" style={{ backgroundColor: "#00AFCA" }}>
                                                    Add to Cart
                                                </Button>
                                                <IconButton aria-label="remove" color="secondary" onClick={() => handleClick(item)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}

                            </Grid>
                        ) : (
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                height="70vh"
                                textAlign="center"
                            >
                                <FavoriteBorderIcon style={{ fontSize: 100, color: '#e0e0e0' }} />
                                <Typography variant="h5" color="textSecondary" gutterBottom>
                                    wishlist is empty.
                                </Typography>
                                <Button variant="contained" style={{ backgroundColor: "#00AFCA" }} href="/">
                                    Go to Shop
                                </Button>
                            </Box>
                        )
                    }
                </Box>
            </Box>
        </Container>
    )
}

export default Wishlist;