import { Box, Card } from '@mui/material';
import { Link } from 'react-router-dom';

function CommonCard({ post }: any) {
  const { cover, title, id } = post;

  const renderCover = (
    <Box
      component="div"
      sx={{
        borderRadius: "10px",
        position: 'relative',
        overflow: 'hidden',
        alignItems: "center",
        justifyContent: "center",
        height: '120px',
        '&:hover img': {
          transform: 'scale(1.2)',
        },
        '&:hover .buttonContainer': {
          opacity: 1,
        },
      }}
    >
      <Link to={`/subcategories/${id}`}>
        <img
          alt={title}
          src={cover}
          style={{
            width: '200px',
            height: '120px',
            objectFit: 'cover',
            transition: 'transform 0.3s ease-in-out',
          }}
        />
        {/* <CardMedia component="img" src={cover} alt={title} style={{ height: "200px", width: "200px" }} /> */}

      </Link>

    </Box>
  );

  return (
    <>
      <Card>{renderCover}</Card>
      <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>{title}</div>
    </>
  );
}

export default CommonCard;