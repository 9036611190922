import './App.css';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <div className="app-container">
        <Header />
        <NavBar />
        <main className="content">
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  );
}

export default App;