import DashboardIcon from '@mui/icons-material/Dashboard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AddressIcon from '@mui/icons-material/LocationOn';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
// import DeleteIcon from '@mui/icons-material/Delete';

const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon style={{ color: '#00AFCA' }} />, route: "/dashboard" },
    { text: 'Orders', icon: <LocalShippingIcon style={{ color: '#00AFCA' }} />, route: "/orders" },
    { text: 'Notification', icon: <NotificationsIcon style={{ color: '#00AFCA' }} />, route: "/notification" },
    { text: 'Address', icon: <AddressIcon style={{ color: '#00AFCA' }} />, route: "/address" },
    { text: 'Account Detail', icon: <AccountBoxIcon style={{ color: '#00AFCA' }} />, route: "/account-details" },
    { text: 'Wishlist', icon: <FavoriteIcon style={{ color: '#00AFCA' }} />, route: "/wishlist" },
    { text: 'Transactions', icon: <MonetizationOnIcon style={{ color: '#00AFCA' }} />, route: "/transactions" },
    { text: 'Customer Support', icon: <SupportAgentIcon style={{ color: '#00AFCA' }} />, route: "/customer-support" },
    { text: 'Refer and earn', icon: <MonetizationOnIcon style={{ color: '#00AFCA' }} />, route: "/refer-earn" },
    { text: 'Wallet', icon: <AccountBalanceWalletIcon style={{ color: '#00AFCA' }} />, route: "/wallet" },
    // { text: 'Delete Account', icon: <DeleteIcon style={{ color: '#00AFCA' }}  />, route: "/delete-account" },
    { text: 'Logout', icon: <ExitToAppIcon style={{ color: '#00AFCA' }} /> },
];

export default menuItems;