import { Dialog, Box, Typography, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { postRequest } from '../Api/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

interface TicketFormProps {
    open: boolean;
    onClose: () => void;
    onSave: (formData: any) => void;
    initialFormData?: any;
}

const TicketForm: React.FC<TicketFormProps> = ({ open, onClose, onSave, initialFormData }) => {
    // console.log("initialFormData :: ", initialFormData);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ticketType, setTicketType] = useState([]);
    const [ticketTypeId, setTicketTypeId] = useState('');

    const [formData, setFormData] = useState(initialFormData || {
        ticket_type: '',
        email: '',
        subject: '',
        description: ''
    });

    const fetchTicketType = async () => {
        try {
            const response = await postRequest("get_ticket_types", navigate, dispatch);
            // console.log("fetchTicketType :: response :: ", response);
            if (!response.error) {
                setTicketType(response.data);
            }
        } catch (error) {
            console.log("fetchTicketType :: error :: ", error);

        }
    }

    useEffect(() => {
        if (initialFormData) {
            setFormData(initialFormData);
        }
        fetchTicketType();
        // eslint-disable-next-line
    }, [initialFormData]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        // console.log("e :: ", e.target);
        if (name === "ticket_type") {
            const selectedType: any = ticketType.find((e: any) => e.title === value);
            if (selectedType) {
                setTicketTypeId(selectedType.id);
            }
        }

        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: any) => {
        console.log("e :: ", formData, ticketTypeId);

        e.preventDefault();
        onSave({ ...formData, ticket_type_id: ticketTypeId });
    };

    // console.log("formData :: ", formData);

    return (
        <Dialog open={open} onClose={onClose}>
            <Box sx={{ p: 6 }}>
                <Typography variant="h5" style={{ textAlign: "center", marginBottom: "10px", fontWeight: "600" }}>{initialFormData ? "Update Ticket" : "Add Ticket"}</Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel id="ticketType-label">Ticket Type</InputLabel>
                                <Select
                                    labelId="ticketType-label"
                                    name="ticket_type"
                                    value={formData.ticket_type}
                                    onChange={handleChange}
                                    label="Ticket Type"
                                >
                                    {ticketType.map((e: any, index) => (
                                        <MenuItem key={index} value={e.title}>
                                            {e.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField fullWidth label="Email" name="email" value={formData.email} onChange={handleChange} required />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Subject" name="subject" value={formData.subject} onChange={handleChange} required />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Description" name="description" value={formData.description} onChange={handleChange} required multiline rows={4} />
                        </Grid>


                        <Grid item xs={12}>
                            <Button variant="contained" style={{ backgroundColor: "#00AFCA" }} type="submit">
                                Send
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Dialog>
    )
}

export default TicketForm;