import { Container, Box, Typography } from '@mui/material';
import UserSidebar from '../components/UserSidebar';

function DeleteAccount() {
    return (
        <Container maxWidth="lg">
            <Box display="flex" mt={3}>
                <UserSidebar />
                <Box sx={{ flexGrow: 1, p: 2 }}>
                    <Typography variant="h5" gutterBottom style={{ paddingTop: "10px", fontWeight: "600" }}>
                        Delete Account
                    </Typography>

                </Box>
            </Box>
        </Container>
    )
}

export default DeleteAccount;