import { useEffect, useState } from 'react';
import { Container, Box, Typography, Button, Snackbar, Alert } from '@mui/material';
import UserSidebar from '../components/UserSidebar';
import AddressForm from '../components/AddressForm';
import AddressTable from '../components/AddressTable';
import { postRequest } from '../Api/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function Address() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [address, setAddress] = useState([]);
    const [addModal, SetAddModal] = useState(false);
    const [editFormData, setEditFormData] = useState(null);
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setMessage] = useState('');

    const fetchAddress = async () => {
        try {
            const details = await postRequest("get_address", navigate, dispatch);
            // console.log("details :: ", details);
            setAddress(details.data);
        } catch (error) {
            console.log("fetchAddress :: error :: ", error);
        }
    };

    const handleSaveAddress = async (formData: any) => {
        try {
            let details;
            console.log("editFormData :: ", editFormData);
            if (editFormData) {
                details = await postRequest("update_address", navigate, dispatch, formData);
            } else {
                details = await postRequest("add_address", navigate, dispatch, { ...formData, "is_default": "0" });
            }
            console.log("editFormData :: ", details);
            if (details.status === 201 || details.status === 200) {
                SetAddModal(false);
                setOpenSnack(true);
                setMessage(details.message);
                fetchAddress();
            }
        } catch (error) {
            console.log("handleSaveAddress :: error :: ", error);
        }
    };

    const handleEdit = (record: any) => {
        console.log("handleEdit :: record :: ", record);
        setEditFormData({ ...record, pincode: record.zipcode });
        SetAddModal(true);
    };

    const handleDelete = async (record: any) => {
        try {
            const details = await postRequest("delete_address", navigate, dispatch, { id: record.id });
            if (details.status === 200) {
                setOpenSnack(true);
                setMessage(details.message);
                fetchAddress();
            }
        } catch (error) {
            console.log("handleDelete :: error :: ", error);
        }
    };

    useEffect(() => {
        fetchAddress();
        // eslint-disable-next-line
    }, []);

    const showModal = () => {
        setEditFormData(null);
        SetAddModal(true);
    };

    const handleClose = () => {
        SetAddModal(false);
    };

    const handleSnackClose = () => {
        setOpenSnack(false);
    };

    return (
        <>
            <Container maxWidth="lg">
                <Box display="flex" mt={3}>
                    <UserSidebar />
                    <Box sx={{ flexGrow: 1, p: 2 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant="h5" gutterBottom style={{ paddingTop: "10px", fontWeight: "600" }}>
                                Address
                            </Typography>
                            <Button variant="contained" onClick={showModal} style={{ backgroundColor: "#00AFCA" }}>
                                Add Address
                            </Button>
                        </div>
                        <br />
                        <AddressTable address={address} onEdit={handleEdit} onDelete={handleDelete} />
                        <AddressForm open={addModal} onClose={handleClose} onSave={handleSaveAddress} initialFormData={editFormData} />
                    </Box>
                </Box>
            </Container>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleSnackClose} severity={"error"} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default Address;