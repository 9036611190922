import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { postRequest } from '../Api/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Faqs() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [faq, setFaq] = useState([]);

    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const fetchFaq = async () => {
        const details = await postRequest("get_faqs", navigate, dispatch);
        console.log("details :: ", details);

        if (details.status === 200) {
            setFaq(details.data);
        }
    }

    useEffect(() => {
        fetchFaq();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Container style={{ marginBottom: "20px" }}>
                <Typography variant="h5" fontWeight={700} sx={{ m: 4 }} style={{ color: "#00AFCA" }}>
                    FAQs
                </Typography>
                {faq.map((e: any, index: number) => (
                    <Accordion
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        key={index}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            sx={{
                                backgroundColor: expanded === `panel${index}` ? "#accff1" : "inherit"
                            }}
                        >
                            {e.question}
                        </AccordionSummary>
                        <AccordionDetails
                        // sx={{
                        //     backgroundColor: expanded === `panel${index}` ? "#E6E6FA" : "inherit"
                        // }}
                        >
                            {e.answer}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container>
        </>
    )
}

export default Faqs;