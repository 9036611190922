import { Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getRequest } from '../Api/api';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function AboutUs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [details, setDetails] = useState("");

  const fetchDetails = async () => {
    const response = await getRequest("get_about_us", navigate, dispatch);
    console.log("response :: ", response);

    setDetails(response.data.about_us_input_description);
  }

  useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line
  }, []);


  return (
    <Container style={{ marginBottom: "20px" }}>
      <Typography variant="h5" fontWeight={700} sx={{ m: 4 }} style={{ justifyContent: 'center', display: 'flex', color: "#00AFCA" }}>
        About Us
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: details }} />
    </Container>
  )
}

export default AboutUs;