import { AppBar, Toolbar, Container, Box, InputBase, Link as MuiLink, useMediaQuery } from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: "20px",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: '500px',
    },
    display: 'flex',
    alignItems: 'center',
    border: '2px solid',
    borderColor: theme.palette.divider,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const Header = () => {
    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <AppBar position="static" elevation={1} sx={{ backgroundColor: "white", marginTop: "10px" }}>
            <Toolbar>
                <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        <Link to="/">
                            <img
                                src="https://res.cloudinary.com/dlrl6ra82/image/upload/v1719834885/gal6zgfxdiy8e9leeu0e.png"
                                alt=""
                                height={"auto"}
                                width={"120px"}
                            />
                        </Link>
                    </Box>
                    {matchesMd && (
                        <Box sx={{ display: 'flex', flexGrow: 1, maxWidth: '500px' }}>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    style={{ color: "black", fontWeight: "500" }}
                                    placeholder="Search for Products..."
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </Search>
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <MuiLink component={Link} to="/contact-us" variant="body1" color="black" underline="none">
                            Support
                        </MuiLink>
                        <MuiLink component={Link} to="/about-us" variant="body1" color="black" underline="none">
                            About Us
                        </MuiLink>
                        <MuiLink component={Link} to="/faqs" variant="body1" color="black" underline="none">
                            FAQs
                        </MuiLink>
                    </Box>
                </Container>
            </Toolbar>
        </AppBar>
    );

}

export default Header;