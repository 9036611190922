import { Container, Box, Typography, Button, Avatar, Grid, Paper, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import UserSidebar from '../components/UserSidebar';
import { styled } from '@mui/system';
import { getRequest } from '../Api/api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const ProfileImage = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: 'auto'
}));

function AccountDetails() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const fetchCustomer = async () => {
        try {
            const details = await getRequest("get_customer", navigate, dispatch);
            console.log("details :: ", details);

            if (!details.error) {
                setName(details.data.name);
                setMobile(details.data.mobile);
            }
        } catch (error) {
            console.log("fetchCustomer :: error :: ", error);
        }
    }

    const [formData, setFormData] = useState({
        username: '',
        mobile: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        profileImage: null,
    });

    const handleChange = (e: any) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };


    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (formData.newPassword !== formData.confirmNewPassword) {
            setPasswordError('New password and confirm new password do not match.');
            return;
        }

        setPasswordError('');

        const data = new FormData();
        data.append('username', formData.username);
        data.append('mobile', formData.mobile);
        data.append('currentPassword', formData.currentPassword);
        data.append('newPassword', formData.newPassword);
        // data.append('confirmNewPassword', formData.confirmNewPassword);
        if (formData.profileImage) {
            data.append('profileImage', formData.profileImage);
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/update_customer`,
                {
                    method: "POST",
                    headers: {
                        "x-access-token": `${localStorage.getItem("authToken")}`,
                    },
                    body: data,
                }
            );
            const res = await response.json();
            console.log('response ::', res);
            if (!res.error) {
                setSuccessMessage('Account details updated successfully.');
                setSuccessDialogOpen(true);
                fetchCustomer();
            }
            else {
                setErrorMessage(res.message || 'An error occurred');
                setErrorDialogOpen(true);
                setFormData({
                    username: name,
                    mobile: mobile,
                    currentPassword: '',
                    newPassword: '',
                    confirmNewPassword: '',
                    profileImage: null,
                });
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('An error occurred while updating account details.');
            setErrorDialogOpen(true);
            setFormData({
                username: name,
                mobile: mobile,
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: '',
                profileImage: null,
            });
        }
    };

    useEffect(() => {
        fetchCustomer();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setFormData({
            ...formData,
            username: name,
            mobile: mobile
        });
        // eslint-disable-next-line
    }, [name, mobile]);

    const handleClose = () => {
        setErrorDialogOpen(false);
        setSuccessDialogOpen(false);
    };

    return (
        <Container maxWidth="lg">
            <Box display="flex" mt={3}>
                <UserSidebar />
                <Box sx={{ flexGrow: 1, p: 2 }}>
                    <Typography variant="h5" gutterBottom style={{ paddingTop: "10px", fontWeight: "600" }}>
                        Account Details
                    </Typography>
                    <Grid item xs={12} md={9}>
                        <Paper elevation={3} sx={{ padding: 2 }}>
                            <Box component="form" onSubmit={handleSubmit} encType="multipart/form-data">
                                <Box textAlign="center">
                                    <ProfileImage src={formData.profileImage ? URL.createObjectURL(formData.profileImage) : ''} />
                                    <Box mt={2}>
                                        <Button variant="contained" component="label" style={{ backgroundColor: "#00AFCA" }}>
                                            Choose File
                                            <input
                                                type="file"
                                                name="profileImage"
                                                hidden
                                                onChange={handleChange}
                                            />
                                        </Button>
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <TextField
                                        fullWidth
                                        label="User Name"
                                        variant="outlined"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <TextField
                                        fullWidth
                                        label="Mobile"
                                        variant="outlined"
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="h6">Password Change</Typography>
                                    <Box mt={2}>
                                        <TextField
                                            fullWidth
                                            type="password"
                                            label="Current password"
                                            variant="outlined"
                                            name="currentPassword"
                                            value={formData.currentPassword}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box mt={2}>
                                        <TextField
                                            fullWidth
                                            type="password"
                                            label="New Password"
                                            variant="outlined"
                                            name="newPassword"
                                            value={formData.newPassword}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box mt={2}>
                                        <TextField
                                            fullWidth
                                            type="password"
                                            label="Confirm New Password"
                                            variant="outlined"
                                            name="confirmNewPassword"
                                            value={formData.confirmNewPassword}
                                            onChange={handleChange}
                                            error={!!passwordError}
                                            helperText={passwordError}
                                        />
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <Button variant="contained" style={{ backgroundColor: "#00AFCA" }} type="submit">Save Changes</Button>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Box>
            </Box>
            <Dialog
                open={errorDialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {errorMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{ backgroundColor: "#00AFCA" }} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={successDialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {successMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{ backgroundColor: "#00AFCA" }} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default AccountDetails;