import { Container, Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import UserSidebar from '../components/UserSidebar';
import React, { useEffect, useState } from 'react';
import { getRequest } from '../Api/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import { format } from 'date-fns';
// import SearchIcon from '@mui/icons-material/Search';

function Notification() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchNotification = async () => {
        const details = await getRequest("getNotification", navigate, dispatch);
        // console.log("fetchNotificatino :: details :: ", details);
        setData(details.data);
    }

    useEffect(() => {
        fetchNotification();
        // eslint-disable-next-line
    }, []);

    return (
        <Container maxWidth="lg">
            <Box display="flex" mt={3}>
                <UserSidebar />
                <Box sx={{ flexGrow: 1, p: 2 }}>
                    <Typography variant="h5" gutterBottom style={{ paddingTop: "10px", fontWeight: "700" }}>
                        Notification
                    </Typography>
                    <Paper sx={{ width: '100%', overflow: 'hidden', mt: 2 }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Title</TableCell>
                                        <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Message</TableCell>
                                        <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Image</TableCell>
                                        <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Date</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        data.length !== 0 ? (
                                            <>
                                                {data
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row: any) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.title}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.message}</TableCell>
                                                                <TableCell>
                                                                    {
                                                                        row.image ?
                                                                            <img src={row.image} alt="" width={100} height={50} /> :
                                                                            <div>
                                                                                <CollectionsOutlinedIcon fontSize='large' />
                                                                                <Typography fontSize={"12px"}>No image</Typography>
                                                                            </div>
                                                                    }
                                                                </TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{format(new Date(row.createAt), 'dd-MM-yyyy h:mm a')}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </>
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={6} style={{ textAlign: 'center', fontSize: "18px", fontFamily: '"Poppins", sans-serif' }}>
                                                    No matching records found
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            </Box>
        </Container>
    )
}

export default Notification;