import {
    Container, Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Card,
    CardContent, Tab, Tabs, TablePagination
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import UserSidebar from '../components/UserSidebar';
import { postRequest } from '../Api/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

function Wallet() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tabValue, setTabValue] = React.useState(0);
    const [data, setData] = useState([]);
    const [amount, setAmount] = useState(0);
    const [requestData, setRequestData] = useState([]);

    const handleChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    const fetchData = async () => {
        try {
            const response = await postRequest("transactions", navigate, dispatch, {
                transaction_type: "wallet"
            });
            // console.log("response :: ", response);
            setData(response.data);
            setAmount(response.balance);
        } catch (error) {
            console.log("fetchData :: error :: ", error);
        }
    }

    const fetchRequest = async () => {
        try {
            const response = await postRequest("get_withdrawal_request", navigate, dispatch, {
                transaction_type: "wallet"
            });
            // console.log("fetchRequest :: ", response);
            setRequestData(response.data);
        } catch (error) {
            console.log("fetchRequest :: error :: ", error);
        }
    }

    useEffect(() => {
        fetchData();
        fetchRequest();
        // eslint-disable-next-line
    }, []);

    // update page count
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (
        <Container maxWidth="lg">
            <Box display="flex" mt={3}>
                <UserSidebar />
                <Box sx={{ flexGrow: 1, p: 2 }}>
                    <Typography variant="h5" gutterBottom style={{ paddingTop: "10px", fontWeight: "600" }}>
                        Wallet
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                        <Card sx={{ width: '100%', mb: 2, boxShadow: "none" }}>
                            <CardContent
                                style={{
                                    border: "1px solid",
                                    maxWidth: "350px",
                                    height: "150px",
                                    background: "linear-gradient(135deg, rgba(61, 0, 85, 1) 13%, rgba(121, 9, 109, 1) 59%, rgba(68, 0, 255, 1) 100%)",
                                    color: "white",
                                    borderRadius: "10px"
                                }}>
                                <Typography variant="body1" marginTop={"5px"}>Total Balance</Typography>
                                <Typography variant="h5" >₹{amount}</Typography>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>

                                    <Typography variant="h6" align="center">Admin</Typography>
                                    <Typography variant="h6" align="center">INR</Typography>
                                </div>
                            </CardContent>
                        </Card>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
                            <Button variant="contained" style={{ backgroundColor: "#00AFCA" }}>Add Money</Button>
                            <Button variant="contained" color="secondary">Withdrawal</Button>
                        </Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleChange} aria-label="wallet tabs">
                                <Tab label="Wallet Transaction" />
                                <Tab label="Wallet Requests" />
                            </Tabs>
                        </Box>
                        {tabValue === 0 && (
                            <Box sx={{ mt: 2, width: '100%' }}>
                                {/* Wallet Transaction Content */}
                            </Box>
                        )}
                        {tabValue === 1 && (
                            <Box sx={{ mt: 2, width: '100%' }}>
                                {/* Wallet Requests Content */}
                            </Box>
                        )}
                    </Box>
                    {
                        tabValue === 0 && (<Paper sx={{ width: '100%', overflow: 'hidden', mt: 2 }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Id</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>User Name</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Type</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Amount</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Status</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Message</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            data.length !== 0 ? (
                                                data
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row: any, index: any) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{index + 1}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.user_name}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.type}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.amount}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{ }</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.message}</TableCell>

                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{format(new Date(row.createAt), 'dd-MM-yyyy h:mm a')}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={6} style={{ textAlign: 'center', fontSize: "18px", fontFamily: '"Poppins", sans-serif' }}>
                                                        No matching records found
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                        )
                    }

                    {
                        tabValue === 1 && (<Paper sx={{ width: '100%', overflow: 'hidden', mt: 2 }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Id</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>User Name</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Payment Address</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Amount</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Status</TableCell>
                                            <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            requestData.length !== 0 ? (
                                                requestData
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row: any, index: any) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{index + 1}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.user_name}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.payment_address}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.amount_requested}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.status}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{format(new Date(row.date_created), 'dd-MM-yyyy h:mm a')}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={6} style={{ textAlign: 'center', fontSize: "18px", fontFamily: '"Poppins", sans-serif' }}>
                                                        No matching records found
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={requestData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                        )
                    }

                </Box>
            </Box>
        </Container>
    )
}

export default Wallet;