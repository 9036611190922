import { NavigateFunction } from "react-router-dom";
import { signOutSuccess } from '../store/userSlice';

export const postRequest = async (url: any, navigate: NavigateFunction, dispatch: any, data?: any) => {
    try {
        const Token = localStorage.getItem("authToken");
        console.log("Token :: ", Token);

        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": `${Token}`,
            },
            body: JSON.stringify(data),
        });

        const responseData = await res.json();
        // console.log("postRequest :: responseData :: ", responseData);

        if (responseData.status === 500) {
            localStorage.removeItem("authToken");
            dispatch(signOutSuccess());
            navigate('/');
            return;
        }

        return responseData;
    } catch (error) {
        console.log("postRequest :: error :: ", error);
    }
}

export const getRequest = async (url: any, navigate: (arg0: string) => void, dispatch: any, data?: any) => {
    try {
        const Token = localStorage.getItem("authToken");
        // console.log("getRequest :: Token :: ", Token);
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": `${Token}`,
            },
            body: JSON.stringify(data),
        });

        const responseData = await res.json();
        // console.log("getRequest :: responseData :: ", responseData);

        if (responseData.status === 500) {
            localStorage.removeItem("authToken");
            // dispatch(signOutSuccess());
            navigate('/');
            return;
        }

        return responseData;
    } catch (error) {
        console.log("getRequest :: error :: ", error);
    }
}