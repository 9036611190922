const pages = [
    {
        name: "HOME",
        route: "/",
    },
    {
        name: "Category",
        route: "/category",
    },
    {
        name: "Products",
        route: "/products",
    },
    // {
    //     name: "Top Offers",
    //     route: "/offers",
    // },

    {
        name: "Contact Us",
        route: "/contact-us",
    },
    {
        name: "About Us",
        route: "/about-us",
    }
];

export default pages;