import React from 'react';
import { Box, Container, Grid, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, YouTube } from '@mui/icons-material';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import PhoneSharpIcon from '@mui/icons-material/PhoneSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';

function Footer() {
    return (
        <Box component="footer" sx={{ mt: 'auto', backgroundColor: 'white', color: 'black', border: '1px solid #ddd' }}>
            <Container maxWidth="lg">

                <Grid container spacing={3} justifyContent="space-between" sx={{ py: 4 }}>
                    <Grid item xs={6}>
                        <div style={{ display: "flex" }}>
                            <div style={{ marginTop: "5px" }}>
                                <LocationOnSharpIcon style={{ color: "#00AFCA" }} />
                            </div>
                            <div>
                                <Typography variant="inherit" color="#00AFCA" fontSize={"20px"} fontWeight={700}>
                                    Find Us
                                </Typography>
                                <Typography variant="body2">
                                    I Square, Science City Road, Sola, Ahmedabad, Gujarat - 370001
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div style={{ display: "flex" }}>
                            <div style={{ marginTop: "5px" }}>
                                <PhoneSharpIcon style={{ color: "#00AFCA" }} />
                            </div>
                            <div>
                                <Typography variant="inherit" color="#00AFCA" fontSize={"20px"} fontWeight={700}>
                                    Call Us
                                </Typography>
                                <Typography variant="body2">
                                    1234567890
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div style={{ display: "flex" }}>
                            <div style={{ marginTop: "5px", marginRight: "4px" }}>
                                <EmailSharpIcon style={{ color: "#00AFCA" }} />
                            </div>
                            <div>
                                <Typography variant="inherit" color="#00AFCA" fontSize={"20px"} fontWeight={700}>
                                    Mail Us
                                </Typography>
                                <Typography variant="body2">
                                    eCart@gmail.com
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={4} justifyContent="space-between" sx={{ py: 4 }}>
                    <Grid item xs={12} sm={12} md={3} textAlign="center">
                        <img src="https://res.cloudinary.com/dlrl6ra82/image/upload/v1719553883/jfkkifvydabzuzcmvggo.png" alt="eCart Logo" style={{ width: '150px' }} />
                        <Typography variant="inherit" fontSize={"20px"} fontWeight={700}>
                            Subscribe
                        </Typography>
                        <Box>
                            <MuiLink href="https://www.facebook.com" target="_blank" color="inherit" sx={{ mx: 1 }}>
                                <Facebook />
                            </MuiLink>
                            <MuiLink href="https://www.twitter.com" target="_blank" color="inherit" sx={{ mx: 1 }}>
                                <Twitter />
                            </MuiLink>
                            <MuiLink href="https://www.instagram.com" target="_blank" color="inherit" sx={{ mx: 1 }}>
                                <Instagram />
                            </MuiLink>
                            <MuiLink href="https://www.youtube.com" target="_blank" color="inherit" sx={{ mx: 1 }}>
                                <YouTube />
                            </MuiLink>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                        <Typography variant="inherit" color="#00AFCA" fontSize={"20px"} fontWeight={700}>
                            Company
                        </Typography>
                        <MuiLink component={Link} to="/about" color="inherit" fontSize={"14px"} underline="none" >
                            About Us
                        </MuiLink>
                        <br />
                        <MuiLink component={Link} to="/contact" color="inherit" fontSize={"14px"} underline="none">
                            Contact Us
                        </MuiLink>
                        <br />
                        <MuiLink component={Link} to="/products" color="inherit" fontSize={"14px"} underline="none">
                            Products
                        </MuiLink>
                        <br />
                        <MuiLink component={Link} to="/categories" color="inherit" fontSize={"14px"} underline="none">
                            Categories
                        </MuiLink>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Typography variant="inherit" color="#00AFCA" fontSize={"20px"} fontWeight={700}>
                            Legal
                        </Typography>
                        <MuiLink component={Link} to="/privacy-policy" color="inherit" fontSize={"14px"} underline="none">
                            Privacy Policy
                        </MuiLink>
                        <br />
                        <MuiLink component={Link} to="/terms-conditions" color="inherit" fontSize={"14px"} underline="none">
                            Terms & Conditions
                        </MuiLink>
                        <br />
                        <MuiLink component={Link} to="/return-policy" color="inherit" fontSize={"14px"} underline="none">
                            Return Policy
                        </MuiLink>
                        <br />
                        <MuiLink component={Link} to="/shipping-policy" color="inherit" fontSize={"14px"} underline="none">
                            Shipping Policy
                        </MuiLink>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Typography variant="inherit" color="#00AFCA" fontSize={"20px"} fontWeight={700}>
                            Resources
                        </Typography>
                        <MuiLink component={Link} to="/support-us" color="inherit" fontSize={"14px"} underline="none" >
                            Support
                        </MuiLink>
                        <br />
                        <MuiLink component={Link} to="/android-app" color="inherit" fontSize={"14px"} underline="none" >
                            Android App
                        </MuiLink>
                        <br />
                        <MuiLink component={Link} to="/ios-app" color="inherit" fontSize={"14px"} underline="none" >
                            iOS App
                        </MuiLink>
                    </Grid>
                </Grid>
            </Container>
            <Box textAlign="center" padding={"20px"} sx={{ backgroundColor: '#00AFCA', color: "white" }}>
                <Typography variant="h5" style={{ fontSize: "medium", fontFamily: '"Poppins", sans-serif' }}>
                    &copy; {new Date().getFullYear()} eCart - ecommerce. All rights reserved Webinent.
                </Typography>
            </Box>
        </Box>
    );
}

export default Footer;