import { Button, Container, TextField, TextareaAutosize, Typography } from '@mui/material';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from "../assets/location-pin.png";

const customIcon = new L.Icon({
    iconUrl: icon,
    iconSize: [32, 32], // Adjust the size as needed
});


function ContactUs() {

    console.log("icon ::: ", icon);
    const mapStyle = {
        height: '400px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        border: '',
        borderRadius: 'none',
    };

    return (
        <>
            <Container style={{ marginBottom: "20px" }}>
                <Typography variant="h5" fontWeight={700} sx={{ m: 4 }} style={{ justifyContent: 'center', display: 'flex', color: "#00AFCA" }}>
                    Contact Us
                </Typography>
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',

                    }}
                >
                    <div style={mapStyle}>
                        <MapContainer
                            center={[23.075575, 72.509063]}
                            zoom={13}
                            style={{
                                height: '100%',
                                width: '70%',
                                border: '1px solid #a09191',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.4)',
                            }}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[23.075575, 72.509063]} icon={customIcon}></Marker>
                        </MapContainer>
                        {/* </div> */}
                    </div>
                    <div style={{ width: '100%' }}>
                        <form style={{ width: '100%', display: 'grid', gridGap: '16px' }}>
                            <TextField
                                label="Username"
                                variant="outlined"
                                placeholder="Enter your name"
                                name="username"
                                style={{ width: '50%' }}
                                // value={formData.username}
                                // onChange={handleChange}
                                required
                            />
                            <TextField
                                label="Email"
                                variant="outlined"
                                placeholder="Enter your email"
                                name="email"
                                fullWidth
                                style={{ width: '50%' }}
                                // value={formData.username}
                                // onChange={handleChange}
                                required
                            />
                            <TextField
                                label="Subject"
                                variant="outlined"
                                name="subject"
                                fullWidth
                                placeholder="Enter your subject"
                                style={{ width: '70%' }}
                                // value={formData.username}
                                // onChange={handleChange}
                                required
                            />
                            <TextareaAutosize
                                minRows={4}
                                // label="Message"
                                placeholder="Enter your message"
                                style={{ width: '70%', borderRadius: '5px', padding: '10px', resize: 'none' }}
                                required
                            />
                            <div style={{ justifyContent: 'center', display: 'flex', width: '70%' }}>
                                <Button variant="contained" style={{ background: '#00AFCA' }}>Send message</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ContactUs;