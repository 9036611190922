import {
    Container, Grid, Box, Typography, Button, TextField, FormControlLabel, Checkbox, Select, MenuItem,
    InputLabel, Divider, Paper, Dialog, Card, CardContent, DialogActions, DialogContent, DialogTitle, IconButton,
    Radio, RadioGroup,
    FormControl,
    FormLabel
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getRequest, postRequest } from '../Api/api';
import CloseIcon from '@mui/icons-material/Close';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AddHomeOutlinedIcon from '@mui/icons-material/AddHomeOutlined';
import cashOnDelivery from "../assets/cod-logo.jpg";
import razorpay from "../assets/Razorpay_logo.png";

const Checkout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [products, setProducts] = useState([]);
    const [balance, setBalance] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [deliveryCharge, setDeliveryCharge] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [total, setTotal] = useState(0);
    const [promocode, setPromocode] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedPromoCode, setSelectedPromoCode] = useState('');
    const [temporaryPromoCode, setTemporaryPromoCode] = useState('');
    const [deliveryMethod, setDeliveryMethod] = useState('delivery');
    const [address, setAddress] = useState('');
    const [userAddress, setUserAddress] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [cartIds, setCartIds] = useState('');
    // address details
    const [openForm, setOpenForm] = useState(false);
    const [cities, setCities] = useState([]);
    const [pincodes, setPincodes] = useState([]);
    const [cityId, setCityId] = useState('');
    const [pincodeId, setPincodeId] = useState('');

    // wallet check
    const [isChecked, setIsChecked] = useState(false);
    const [walletUsed, setWalletUsed] = useState(false);
    const handleCheckboxChange = (event: any) => {
        console.log(" -- :: ", event.target.checked);

        setIsChecked(event.target.checked);
        setWalletUsed(event.target.checked);
    };

    // open offer dialog
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // set promo code
    const selectPromoCode = (promoCode: any) => {
        console.log("selectedPromoCode :: ", promoCode);

        setSelectedPromoCode(promoCode);
        handleClose();
    };

    const handlePromoCodeSelect = (promoCode: any) => {
        setTemporaryPromoCode(promoCode);
    };

    const handleApplyClick = () => {
        selectPromoCode(temporaryPromoCode);
        handleClose();
    };

    // handle address value
    const handleRadioChange = (event: any) => {
        console.log("handleRadioChange :: event :: ", event.target.value);
        setSelectedValue(event.target.value);
        setAddress(event.target.value);
    };

    // open address form
    const handleOpenAddress = () => {
        setOpenForm(true);
    }

    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        alternate_mobile: '',
        address: '',
        country: '',
        state: '',
        city: '',
        area: '',
        pincode: '',
        type: 'home',
    });

    const fetchCity = async () => {
        try {
            const details = await postRequest("get_cities", navigate, dispatch);
            console.log("fetchCity :: details :: ", details);
            setCities(details.data);
        } catch (error) {
            console.log("fetchCity ::  error :: ", error);
        }
    }

    const fetchPincode = async (cityId: any) => {
        try {
            console.log("fetchPincode :: cityId :: ", cityId);
            let data = {};
            if (cityId) {
                data = { id: cityId }
            }

            const details = await postRequest("get_zipcode_by_city_id", navigate, dispatch, data);
            console.log("fetchPincode :: details :: ", details);
            setPincodes(details.data);
        } catch (error) {
            console.log("fetchPincode ::  error :: ", error);
        }
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        console.log("e :: ", e.target);
        if (name === "city") {
            const selectedCity: any = cities.find((city: any) => city.name === value);
            if (selectedCity) {
                setCityId(selectedCity.id);
            }
        }
        if (name === "pincode") {
            const selectedPincode: any = pincodes.find((e: any) => e.zipcode === value);
            if (selectedPincode) {
                setPincodeId(selectedPincode.id);
            }
        }
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            let response = await postRequest("add_address", navigate, dispatch, { ...formData, city_id: cityId, pincode_name: pincodeId, "system_pincode": "0", "is_default": "0" });
            console.log("handleSubmit :: response :: ", response);

            if (response.status === 201) {
                fetchAddress();
                setOpenForm(false);
            }
        } catch (error) {
            console.log("error :: ", error);
            setOpenForm(false);
        }
    };

    useEffect(() => {
        if (cityId) {
            fetchPincode(cityId);
        }
        // eslint-disable-next-line
    }, [cityId]);

    // payment method
    const handlePaymentMethodChange = (event: any) => {
        setPaymentMethod(event.target.value);
    };

    const fetchDetails = async () => {
        try {
            const response = await postRequest("get_settings", navigate, dispatch);
            console.log("fetchDetails :: response :: ", response);
            if (!response.error) {
                setDeliveryCharge(Number(response.data.system_settings[0].delivery_charge));
            }
        } catch (error) {
            console.log("fetchDetails :: error :: ", error);
        }
    }

    const fetchCart = async () => {
        try {
            const response = await postRequest("get_user_cart", navigate, dispatch);
            console.log("fetchCart :: response :: ", response);
            setProducts(response.data);
            setCartIds(response.data.map((item: { id: any; }) => item.id).join(','));
            setSubTotal(Number(response.sub_total));
        } catch (error) {
            console.log("fetchCart :: error :: ", error);
        }
    }

    const fetchPromoCode = async () => {
        try {
            const response = await postRequest("get_promo_codes", navigate, dispatch);
            console.log("fetchPromoCode :: response :: ", response);
            if (!response.error) {
                setPromocode(response.promo_codes);
            }
        } catch (error) {
            console.log("fetchPromocode :: error :: ", error);
        }
    }

    // validate promo code
    const handleCoupon = async () => {
        try {
            const response = await postRequest("validate_promo_code", navigate, dispatch, {
                promo_code: selectedPromoCode,
                final_total: subTotal + 99
            });
            console.log("handleCoupon :: response :: ", response);
            if (!response.error && response.message === "The promo code is valid") {
                setDiscount(response.data.final_discount);
            }
        } catch (error) {
            console.log("handleCoupon :: error :: ", error);
        }
    }

    const fetchAddress = async () => {
        try {
            const details = await postRequest("get_address", navigate, dispatch);
            console.log("fetchAddress :: details :: ", details);
            setUserAddress(details.data);
        } catch (error) {
            console.log("fetchAddress :: error :: ", error);
        }
    };

    const fetchCustomer = async () => {
        try {
            const details = await getRequest("get_customer", navigate, dispatch);
            console.log("details :: ", details);
            setBalance(details.data.balance);
        } catch (error) {
            console.log("fetchAddress :: error :: ", error);
        }
    }

    const handlePlaceOrder = async () => {
        try {
            console.log("handlePlaceOrder :: ");

            // door step delivery - local_pickup -0
            let data = {
                cart_id: cartIds,
                address_id: address,
                promo_code: selectedPromoCode,
                promo_discount: balance,
                total: subTotal,
                delivery_charge: deliveryCharge,
                final_total: total,
                is_wallet_used: walletUsed,
                wallet_balance_used: balance,
                order_note: "",
                payment_method: paymentMethod,
                tax_percentage: "",
                attachments: "",
                local_pickup: deliveryMethod === "delivery" ? "0" : "1",
                delivery_date: "",
                delivery_time: ""
            }
            console.log("data :: ", data);

            if ((!data.cart_id || !data.total || !data.payment_method || !data.local_pickup) && (!data.address_id && data.local_pickup === "1")) {
                console.error("handlePlaceOrder :: error :: Missing required fields");
                alert("Error: Please fill all the required fields.");
                return;
            }

            const response = await postRequest("place_order", navigate, dispatch, data);
            console.log(" handlePlaceOrder :: response :: ", response);
            if (response.status === 200 && response.message === "Order Placed.") {
                navigate("/success");
            }

        } catch (error) {
            console.log("handlePlaceOrder :: error :: ", error);
        }
    }

    useEffect(() => {
        fetchCity();
        fetchPincode(null);
        fetchCart();
        fetchPromoCode();
        fetchDetails();
        fetchAddress();
        fetchCustomer();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let newTotal = subTotal + deliveryCharge - discount;
        if (walletUsed) {
            newTotal -= balance;
        }
        setTotal(newTotal);
        // setTotal(subTotal + deliveryCharge - discount);
    }, [subTotal, deliveryCharge, discount, balance, walletUsed]);

    return (
        <>
            <Container sx={{ mt: 4, mb: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4, mb: 4, background: "#00AFCA", padding: "20px", borderRadius: "15px" }}>
                    <Box onClick={() => { navigate("/cart") }} sx={{ textAlign: 'center', p: 2, color: 'white', mx: 1 }}>
                        <Typography variant="h6" fontSize={"x-large"} style={{ cursor: "pointer" }}>Shopping Cart</Typography>
                    </Box>
                    <Typography variant="h6" sx={{ mx: 2, color: 'white' }}>→</Typography>
                    <Box sx={{ textAlign: 'center', p: 2, color: 'white', mx: 1 }}>
                        <Typography variant="h6" fontSize={"x-large"} style={{ borderBottom: "2px solid white" }}>Checkout</Typography>
                    </Box>
                    <Typography variant="h6" sx={{ mx: 2, color: 'white' }}>→</Typography>
                    <Box sx={{ textAlign: 'center', p: 2, color: 'white', mx: 1 }}>
                        <Typography variant="h6" fontSize={"x-large"}>Order Complete</Typography>
                    </Box>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={7}>
                        <Box>
                            <Typography variant="h6" sx={{ mb: 2, fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Billing Details</Typography>
                            <Box sx={{ mb: 2 }}>
                                <Button variant={deliveryMethod === 'delivery' ? 'contained' : 'outlined'} onClick={() => setDeliveryMethod('delivery')}>
                                    Door Step Delivery
                                </Button>
                                <Button variant={deliveryMethod === 'pickup' ? 'contained' : 'outlined'} sx={{ ml: 2 }} onClick={() => setDeliveryMethod('pickup')}>
                                    Pickup From Store
                                </Button>
                            </Box>

                            {deliveryMethod === 'delivery' && (
                                <>
                                    <Divider sx={{ mt: 2, mb: 2 }} />
                                    <div style={{ display: "flex" }}>
                                        <HomeOutlinedIcon fontSize='medium' style={{ marginRight: "5px", color: "black" }} />
                                        <Typography variant="body1" sx={{ mb: 2, fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Shipping Address</Typography>
                                    </div>
                                    {
                                        userAddress.length === 0 ? (
                                            <Typography variant="body2" sx={{ mb: 2, fontFamily: '"Poppins", sans-serif', color: userAddress.length === 0 ? "red" : "green" }}>
                                                Please Add Address For delivery.
                                            </Typography>
                                        ) : (
                                            <>
                                                <Card variant="outlined" sx={{ mt: 2, mb: 2 }}>
                                                    {
                                                        userAddress.map((e: any) => (
                                                            <CardContent key={e.id} style={{ display: 'flex', alignItems: 'center' }}>
                                                                <RadioGroup value={selectedValue} onChange={handleRadioChange}>
                                                                    <FormControlLabel
                                                                        value={e.id}
                                                                        control={<Radio />}
                                                                        label={
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <div>
                                                                                    <Typography variant="body1"><strong>{e.name}</strong> - {e.type}</Typography>
                                                                                    <Typography variant="body2">
                                                                                        {e.address}
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {e.area}, {e.city}, {e.zipcode}
                                                                                    </Typography>
                                                                                    <Typography variant="body2">{e.mobile}</Typography>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </RadioGroup>
                                                            </CardContent>
                                                        ))
                                                    }
                                                </Card>

                                            </>
                                        )
                                    }
                                    <div style={{ display: "flex", justifyContent: "end", marginRight: "18px" }}>
                                        <Button variant="text" style={{ backgroundColor: "#00AFCA" }} onClick={handleOpenAddress}>
                                            <AddHomeOutlinedIcon fontSize='small' style={{ marginRight: "5px", color: '#00AFCA' }} />
                                            Create a New Address
                                        </Button>
                                    </div>

                                    {
                                        userAddress.length !== 0 && (
                                            <Typography variant="body2" sx={{ mb: 2, fontFamily: '"Poppins", sans-serif', color: !address ? "red" : "green" }}>
                                                {
                                                    !address
                                                        ? ("Please select address.")
                                                        : ("All the products are deliverable on the selected address")
                                                }
                                            </Typography>
                                        )
                                    }

                                </>
                            )}

                            <Divider sx={{ mt: 2, mb: 2 }} />
                            <Typography variant="h6" sx={{ mt: 4, mb: 2, fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Select Payment Method</Typography>
                            <RadioGroup
                                row
                                value={paymentMethod}
                                onChange={handlePaymentMethodChange}
                            >
                                <FormControlLabel
                                    value="COD" control={<Radio />} label={
                                        <img src={cashOnDelivery} alt="cod" style={{ width: 100 }} />
                                    }
                                />

                                <FormControlLabel value="RazorPay" control={<Radio />} label={
                                    <img src={razorpay} alt="razorpay" style={{ width: 100 }} />
                                } />
                            </RadioGroup>

                            <Divider sx={{ mt: 2, mb: 2 }} />

                            <TextField
                                fullWidth
                                label="Special Note for Order"
                                variant="outlined"
                                margin="dense"
                            />

                            {/* <Typography variant="h6" color="black" sx={{ mt: 4 }}>
                                Upload Order Attachments if you have any (Only images and docs are allowed)
                            </Typography>
                            <Button variant="contained" component="label" sx={{ mt: 1 }}>
                                Choose Files
                                <input type="file" hidden />
                            </Button>
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                Attachments is required
                            </Typography> */}
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <Paper sx={{ p: 3 }}>

                            <Typography variant="h6" sx={{ fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Wallet Balance</Typography>
                            <Box>
                                <FormControlLabel
                                    control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
                                    label={`Available balance : ₹${balance}`}
                                />
                            </Box>
                            <Divider sx={{ mt: 1, mb: 2 }} />

                            <Typography variant="h6" sx={{ mt: 4, fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>My Orders</Typography>
                            <Divider sx={{ mt: 1, mb: 2 }} />
                            <Box sx={{ mb: 2 }}>
                                <Grid container spacing={2}>
                                    {
                                        products.map((item: any, index: any) => (
                                            <>
                                                <Grid item xs={4} key={index}>
                                                    <Box>
                                                        <img src={item.product_details[0].image} alt={item.product_details[0].name} style={{ width: 100, marginRight: 16 }} />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography fontWeight={600}>{item.product_details[0].name}</Typography>
                                                    <Typography variant="body2">Qty: {item.qty}</Typography>
                                                    <Typography variant="body2">GST: 10%</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="right">
                                                    <Typography>₹{item.net_amount}</Typography>
                                                </Grid>
                                            </>
                                        ))
                                    }
                                </Grid>
                            </Box>
                            <br />
                            <Box>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography fontSize={"larger"}>Subtotal</Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="right">
                                        <Typography>₹{subTotal}</Typography>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ mt: 1, mb: 1 }} />

                                {deliveryMethod === 'delivery' && (
                                    <>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography fontSize={"larger"}>Delivery Charge</Typography>
                                            </Grid>
                                            <Grid item xs={6} textAlign="right">
                                                <Typography>₹{deliveryCharge}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ mt: 1, mb: 1 }} />
                                    </>
                                )}
                                {
                                    discount !== 0 && (
                                        <>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography fontSize={"larger"}>Discount</Typography>
                                                </Grid>
                                                <Grid item xs={6} textAlign="right">
                                                    <Typography color={"green"}>- ₹{discount}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider sx={{ mt: 1, mb: 1 }} />
                                        </>
                                    )
                                }
                                {
                                    walletUsed && (
                                        <>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography fontSize={"larger"}>Wallet</Typography>
                                                </Grid>
                                                <Grid item xs={6} textAlign="right">
                                                    <Typography color={"green"}>- ₹{balance}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider sx={{ mt: 1, mb: 1 }} />
                                        </>
                                    )
                                }
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography fontSize={"larger"}>Total</Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="right">
                                        <Typography variant="h6">₹{total}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 2 }}>
                                    <Grid item xs={7}>
                                        <TextField
                                            fullWidth
                                            label="Coupon Code"
                                            variant="outlined"
                                            margin="normal"
                                            size="small"
                                            value={selectedPromoCode}
                                            onChange={(e) => setSelectedPromoCode(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={5} mt={1} display="flex" alignItems="center" justifyContent="flex-end">
                                        <Button variant="contained" onClick={handleCoupon}>
                                            Apply Coupon
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Button variant="text" onClick={handleClickOpen}>See All Offers(%)</Button>

                                <Button variant="contained" fullWidth sx={{ mt: 2 }} onClick={handlePlaceOrder}>
                                    Place Order
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container >
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h6">Promo code</Typography>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {
                        promocode.map((e: any, index: any) => (
                            <Card variant="outlined" sx={{ mb: 2 }} key={index} onClick={() => handlePromoCodeSelect(e.promo_code)}>
                                <CardContent style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}>
                                    <img src={e.image} alt="" style={{ width: 100, marginRight: 16 }} />
                                    <div>
                                        <Typography variant="h5">{e.promo_code}</Typography>
                                        <Typography variant="body2">
                                            {e.message}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        ))
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleApplyClick}>Apply</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openForm}>
                <Box sx={{ p: 6 }}>
                    <Typography variant="h5" style={{ textAlign: "center", marginBottom: "10px", fontWeight: "600" }}>{"Add Address"}</Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Name" name="name" value={formData.name} onChange={handleChange} required />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Mobile Number" name="mobile" value={formData.mobile} onChange={handleChange} required />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Alternate Mobile" name="alternate_mobile" value={formData.alternate_mobile} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Address" name="address" value={formData.address} onChange={handleChange} required multiline rows={4} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Country" name="country" value={formData.country} onChange={handleChange} required />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="State" name="state" value={formData.state} onChange={handleChange} required />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required>
                                    <InputLabel id="city-label">City</InputLabel>
                                    <Select
                                        labelId="city-label"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        label="City"
                                    >
                                        {cities.map((city: any, index) => (
                                            <MenuItem key={index} value={city.name}>
                                                {city.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Area" name="area" value={formData.area} onChange={handleChange} required />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required>
                                    <InputLabel id="pincode-label">Pincode</InputLabel>
                                    <Select
                                        labelId="pincode-label"
                                        name="pincode"
                                        value={formData.pincode}
                                        onChange={handleChange}
                                        label="Pincode"
                                    >
                                        {pincodes.map((pincode: any, index) => (
                                            <MenuItem key={index} value={pincode.zipcode}>
                                                {pincode.zipcode}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Type</FormLabel>
                                    <RadioGroup row name="type" value={formData.type} onChange={handleChange}>
                                        <FormControlLabel value="home" control={<Radio />} label="Home" />
                                        <FormControlLabel value="office" control={<Radio />} label="Office" />
                                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" style={{ backgroundColor: "#00AFCA" }} type="submit">
                                    Save Address
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Dialog>
        </>
    )
}

export default Checkout;