import { Box, Typography, Button, Divider, TextField, IconButton, Drawer, Snackbar, Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { postRequest } from '../Api/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signInFailure, signInSuccess } from '../store/userSlice';

const Cart = ({ toggleDrawer }: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const [count, setCount] = useState(1);
    const [open, setOpen] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState(false);
    const [loginMsg, setLoginMsg] = useState('');

    const handleLogin = async () => {
        try {
            const data = {
                mobile: mobileNumber,
                password: password,
            }
            const response = await postRequest("sign_in", navigate, dispatch, data);
            // console.log("handleLogin :: response :: ", response);
            localStorage.setItem("authToken", response.data.token);
            // dispatch(signInSuccess(response));
            dispatch(signInSuccess(response));
            setOpen(false);
            setLoginMsg(response.message);
            setMsg(true);
        } catch (error) {
            setLoginMsg("Something went wrong!");
            dispatch(signInFailure("Something went wrong!"));
            console.error("handleLogin :: Error: ", error);
        }
    }

    const fetchCart = async () => {
        let user = localStorage.getItem("authToken");
        console.log("fetchCart :: user :: ", user);
        if (!user && user === null) {
            let cart_data = localStorage.getItem("cart");
            console.log("cart_data :: ", cart_data);

            if (cart_data) {
                let data = JSON.parse(cart_data);
                let sumOfA = 0;
                for (let obj of data) {
                    sumOfA += Number(obj.special_price);
                }
                setTotal(sumOfA)
                setCart(data);
            }
        }
        else {
            const response = await postRequest("get_user_cart", navigate, dispatch);
            console.log("Cart :: response :: ", response);
            setTotal(response.sub_total);
            setCart(response.data);
        }
    }

    const increment = () => {
        setCount(prevCount => prevCount + 1);
    };

    const decrement = () => {
        setCount(prevCount => Math.max(1, prevCount - 1));
    };

    const handleClick = async (e: any) => {
        let user = localStorage.getItem("authToken");
        console.log("user :: ", user);

        console.log("e :: ", e);
        // cart_id, product_id, product_variant_id, user_id 
        try {
            if (!user && user === null) {
                let cart_data = localStorage.getItem("cart");
                if (cart_data) {
                    let data = JSON.parse(cart_data);
                    console.log("data :: ", data);

                    data = data.filter((p: any) => {
                        return p.product_id !== e.product_id
                    });
                    console.log("data :: :: 1 : : ", data);

                    let sumOfA = 0;
                    for (let obj of data) {
                        sumOfA += obj.special_price;
                    }
                    setTotal(sumOfA);
                    setCart(data);
                    localStorage.setItem("cart", JSON.stringify(data));
                    window.dispatchEvent(new Event("cartUpdated"));
                }
            } else {
                const data = {
                    cart_id: e.id,
                    product_id: e.product_details[0].id,
                }
                const response = await postRequest("remove_from_cart", navigate, dispatch, data);
                console.log("response :: ", response);
                window.dispatchEvent(new Event("cartUpdated"));
                fetchCart();
            }
        } catch (error) {
            console.log("handleClick :: error :: ", error);

        }
    }

    const handleCart = async () => {
        const user = localStorage.getItem("authToken");
        if (!user) {
            setOpen(true);
        } else {
            navigate("/cart");
            toggleDrawer();
        }
    }

    const handleCheckOut = async () => {
        const user = localStorage.getItem("authToken");
        if (!user) {
            setOpen(true);
        } else {
            navigate("/checkout");
            toggleDrawer();
        }
    }

    useEffect(() => {
        fetchCart();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Box flex="1" overflow="auto" p={2}>
                <Box>
                    {cart.map((e: any) => (
                        <Box key={e.id} >
                            <div style={{ display: "flex", justifyContent: "end" }}>
                                <IconButton onClick={() => {
                                    handleClick(e)
                                }}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <Box display="flex">
                                <img src={e.image ?? e.product_details[0].image} alt="Sofa" style={{ width: 80, height: 80, marginRight: 16 }} />
                                <Box>
                                    <Typography variant="body1">{e.name ?? e.product_details[0].name}</Typography>
                                    <Button variant="outlined" onClick={decrement} sx={{ minWidth: '10px', padding: "0 8px" }}>-</Button>
                                    <TextField
                                        value={count}
                                        inputProps={{ readOnly: true, style: { textAlign: 'center', padding: "2px 0" } }}
                                        sx={{ width: '30px', margin: '0 5px' }}
                                    />
                                    <Button variant="outlined" onClick={increment} sx={{ minWidth: '10px', padding: "0 8px" }}>+</Button>

                                    {/* <Typography variant="body2" color="textSecondary">{e.description}</Typography> */}
                                    <Typography variant="h5" style={{ color: "#00AFCA", marginTop: "5px", fontSize: "16px" }}>₹{e.special_price ?? e.net_amount}</Typography>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center" margin={"10px 0"}>

                            </Box>
                            <Divider />
                        </Box>
                    ))}
                </Box>

            </Box>


            <Box p={2} sx={{ borderTop: '1px solid #ddd' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="body1">Subtotal:</Typography>
                    <Typography variant="h6" style={{ color: "#00AFCA" }}>₹{total}</Typography>
                </Box>
                <Button variant="contained" fullWidth
                    style={{
                        marginBottom: "5px", color: "#00AFCA", background: "#dce3e9", fontWeight: "600"
                    }}
                    onClick={handleCart}
                >
                    View Cart
                </Button>
                <Button variant="contained" fullWidth style={{ background: '#00AFCA' }}
                    onClick={handleCheckOut}
                >
                    Checkout</Button>
            </Box>

            <Drawer
                anchor="right"
                open={open}
                onClose={() => {
                    setOpen(false)
                    toggleDrawer()
                }}
            >
                <Box
                    sx={{ width: 350, padding: 2 }}
                    role="presentation"
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Register</Typography>
                        <IconButton onClick={() => { setOpen(false) }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <TextField
                        label="Mobile Number"
                        type="tel"
                        fullWidth
                        margin="normal"
                        required
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button variant="contained" style={{ backgroundColor: "#00AFCA" }} fullWidth sx={{ mt: 2 }} onClick={handleLogin}>
                        Login
                    </Button>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
                        <Box>
                            <Button variant="text">Forgot Password?</Button>
                        </Box>
                        <Box>
                            <Button variant="text">Remember Me</Button>
                        </Box>
                    </Box>
                    <Divider />
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Button variant="text">Create New Account</Button>
                    </Box>
                </Box>
            </Drawer>

            <Snackbar open={msg} autoHideDuration={6000} onClose={() => setMsg(false)}>
                <Alert
                    onClose={() => setMsg(false)}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {loginMsg}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Cart;