import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { CardMedia, Grid, Typography } from '@mui/material';

function SubCategoryCard({ post }: any) {
    const { cover, title, id } = post;
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(`/products/${id}`, { state: { id: id } });
        console.log('Read More', post);
    };

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card style={{ borderRadius: "20px", position: 'relative' }}>
                <Box
                    sx={{
                        position: 'relative',
                        overflow: 'hidden',
                        // margin: '20px 0',
                        '&:hover .overlay': {
                            top: 0
                        },
                    }}
                >
                    <CardMedia
                        component="img"
                        src={cover}
                        alt={title}
                        sx={{ height: { xs: "200px", sm: "200px" }, width: { xs: "350px", sm: "300px" } }}
                    />

                    <Box
                        className="overlay"
                        sx={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            padding: 1,
                            background: 'rgba(22, 121, 171, 0.5)',
                            overflow: 'hidden',
                            height: 'auto',
                            transition: 'top 0.5s ease',
                            top: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '&:hover': {
                                top: 0,
                            },
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                color: 'white',
                                fontSize: { xs: '3vw', sm: '1.2vw' },
                                // position: 'absolute',
                                // top: '50%',
                                // left: '50%',
                                // transform: 'translate(-50%, -50%)',
                                textAlign: 'center',
                                // width: '100%'
                                marginBottom: 2
                            }}
                        >
                            {title}
                        </Typography>
                        <Box
                            className="buttonContainer"
                            sx={{
                                // marginTop: 1,
                                // opacity: 0,
                                // transition: 'opacity 0.3s ease-in-out',
                                textAlign: "center",
                                // marginTop: 15,
                            }}
                        >
                            <Button
                                variant="contained"
                                style={{ background: '#00AFCA' }}
                                onClick={handleButtonClick}
                            >
                                Read More
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Grid>
    );
}

export default SubCategoryCard;

SubCategoryCard.propTypes = {
    post: PropTypes.object.isRequired,
    index: PropTypes.number,
};