import { Box, Button, Container, Grid, Typography } from '@mui/material';
import UserSidebar from '../components/UserSidebar';
import menuItems from '../constants/menuItems';
import { useNavigate } from 'react-router-dom';
import { signOutSuccess } from '../store/userSlice';
import { useDispatch } from 'react-redux';

function DashBoard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = (event: any, page: any, route: any) => {
        console.log(" --  :: -event- :: --  :: ", event);
        console.log(" --  :page: -- :: ", page);
        console.log(" --  :route: -- :: ", route);
        if (page === "Logout") {
            localStorage.removeItem("authToken");
            dispatch(signOutSuccess());
        }
        navigate(route);
    }

    return (
        <Container maxWidth="lg">
            <Box display="flex" mt={3}>
                <UserSidebar />

                <Box sx={{ flexGrow: 1, p: 2, width: "min-content" }} >
                    <Typography variant="h5" gutterBottom style={{ paddingTop: "10px" }}>
                        Hello Admin
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ paddingTop: "10px" }}>
                        From your account dashboard you can view your recent orders, manage your shipping and billing addresses, and edit your password and account details.
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            {menuItems.slice(1, 12).map((item, index) => (
                                <Grid item xs={12} sm={4} md={4} key={index}>
                                    <Button
                                        variant="outlined"
                                        startIcon={item.icon}
                                        fullWidth
                                        sx={{ height: 90, borderColor: "#00AFCA", color: '#00AFCA' }}
                                        onClick={(event) => {
                                            handleClick(
                                                event,
                                                item.text,
                                                item.route ? item.route : "/"
                                            );
                                        }}
                                    >
                                        {item.text}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default DashBoard;