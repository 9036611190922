import { useEffect, useState } from 'react';
import { Container, Grid, Typography, Button, Card, CardContent, CardMedia, Rating, Box, TextField, Divider } from '@mui/material';
// import ShareIcon from '@mui/icons-material/Share';
// import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getRequest, postRequest } from '../Api/api';
import { useDispatch } from 'react-redux';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import "../pages/index.css";

interface Product {
    warranty_period: string;
    cod_allowed: any;
    is_returnable: any;
    is_cancelable: any;
    description: any;
    _id: string;
    product_name: string;
    product_identity: string;
    category: string;
    subCategory: string;
    price: number;
    special_price: number;
    main_image: string;
    product_variant?: ProductVariant[];
    attributes?: Attribute[];
    short_description: string;
    rating: number;
    no_of_ratings: number;
    tax: string;
}

interface ProductVariant {
    _id: string;
    price: number;
    special_price: number;
    variant_image?: { image: string }[];
    attributeIds: string[];
}

interface Attribute {
    attributeSetId: string;
    name: string;
    attrs: { attributeId: string; attribute_name: string; value: string }[];
}

const ProductDetails: React.FC = () => {
    let user = localStorage.getItem("authToken");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const [product, setProduct] = useState<Product | null>(null);
    const [productReview, setProductReview] = useState<any[]>([]);
    const [subCategory, setSubCategory] = useState<string>('');
    const [relatedProducts, setRelatedProducts] = useState<any[]>([]);
    const [count, setCount] = useState(1);

    // update variant wise
    const [selectedVariant, setSelectedVariant] = useState<ProductVariant | null>(null);

    const increment = () => { setCount(prevCount => prevCount + 1); };
    const decrement = () => { setCount(prevCount => Math.max(1, prevCount - 1)); };

    const fetchProduct = async () => {
        try {
            const details = await getRequest(`getAllProduct?product_id=${id}`, navigate, dispatch);
            console.log("details :: ", details);
            setProduct(details.data[0]);
            console.log("details.data[0].subCategoryId :: ", details.data[0].subCategoryId);
            setSubCategory(details.data[0].subCategoryId);
        } catch (error) {
            console.log("fetchProduct :: error :: ", error);
        }
    }

    const fetchReview = async () => {
        try {
            const details = await getRequest(`getProductReview?product_id=${id}`, navigate, dispatch);
            console.log("fetchReview :: details :: ", details);
            setProductReview(details.data);
        } catch (error) {
            console.log("fetchReview :: error :: ", error);
        }
    }

    const fetchRelatedProduct = async () => {
        try {
            console.log("subCategory :: ", subCategory);
            if (!subCategory) return;
            const details = await getRequest(`getAllProduct?subCategory_id=${subCategory}`, navigate, dispatch);
            const newdata = details.data.filter((e: any) => e._id !== id);
            console.log("fetchRelatedProduct :: newdata :: ", newdata);
            setRelatedProducts(newdata);
        } catch (error) {
            console.log("fetchRelatedProduct :: error :: ", error);
        }
    }

    useEffect(() => {
        fetchProduct();
        fetchReview();
        fetchRelatedProduct();
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        fetchRelatedProduct();
        // eslint-disable-next-line
    }, [subCategory]);

    const handleClick = async (event: any, variant: any) => {
        console.log("event ::", event);
        console.log("selectedVariant :: --------- :: ", variant);

        if (!user && user === null) {

            let product = {
                product_id: event._id,
                name: event.product_name,
                description: event.short_description,
                image: variant ? variant.variant_image ? variant.variant_image[0].image : event.main_image : event.image,
                price: variant ? variant.price : event.product_variant[0].price,
                special_price: variant ? variant.special_price : event.product_variant[0].special_price
            }

            let cart_data = localStorage.getItem("cart");
            console.log("cart_data :: ", cart_data);
            let newData = cart_data ? JSON.parse(cart_data) : [];
            newData.push(product);
            console.log("newData :: ", newData);

            localStorage.setItem("cart", JSON.stringify(newData));
            window.dispatchEvent(new Event("cartUpdated"));
        } else {
            // product_id, product_variant_id, user_id, qty
            const response = await postRequest("manage_cart", navigate, dispatch, {
                product_id: event._id,
                product_variant_id: variant._id,
                qty: 1
            });
            console.log("reponse :: ", response);
            window.dispatchEvent(new Event("cartUpdated"));
        }
    }

    const handleVariantChange = (variant: ProductVariant) => setSelectedVariant(variant);

    if (!product) return <div>Loading...</div>;

    const defaultVariant = product.product_variant && product.product_variant.length > 0 ? product.product_variant[0] : null;
    const currentVariant = selectedVariant || defaultVariant;

    const getVariantImage = () => {
        if (currentVariant?.variant_image && currentVariant.variant_image.length > 0) {
            return currentVariant.variant_image[0]?.image;
        }
        return product.main_image;
    };

    return (
        <Container style={{ marginTop: "20px" }}>
            {
                product && (
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    image={getVariantImage()}
                                    alt="product image"
                                    height="500px"
                                    width="100px"
                                />
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5">{product.product_name}</Typography>
                            <div style={{ display: "flex", margin: "10px 0" }}>
                                <Typography variant='h5' style={{ fontFamily: '"Poppins", sans-serif', fontSize: "medium", fontWeight: "bold", marginRight: "8px" }}>{product.category}</Typography>
                                {" | "}
                                <Typography variant='h5' style={{ fontFamily: '"Poppins", sans-serif', fontSize: "medium", fontWeight: "bold", marginLeft: "8px" }}>{product.subCategory}</Typography>
                            </div>
                            <div style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: product.short_description }} />
                            <div style={{ display: "flex", padding: "15px 0" }}>
                                <Rating name="size-medium" value={product.rating} readOnly />({product.no_of_ratings} Reviews)
                            </div>

                            <Typography variant="h5" color="secondary" style={{ color: "black" }}>
                                ₹{currentVariant?.special_price ?? product.special_price}{" "}
                                <Typography component="span" variant="body1" color="textSecondary" style={{ textDecoration: 'line-through', color: "#d32f2f" }}>
                                    ₹{currentVariant?.price ?? product.price}
                                </Typography>
                            </Typography>

                            {
                                product.tax === "NIL" && (
                                    <Typography style={{ fontSize: "small", fontFamily: '"Poppins", sans-serif' }}>(Inclusive of all taxes)</Typography>
                                )
                            }


                            {product.attributes && product.attributes.map((attributeSet: any) => (
                                <div key={attributeSet.attributeSetId} style={{ margin: "10px 0" }}>
                                    <div style={{ display: "flex" }}>
                                        <p style={{ fontWeight: "bold" }}>{attributeSet.name}:</p>
                                        <div style={{ display: "flex", flexWrap: "wrap", gap: '10px', width: "100%" }}>
                                            {attributeSet.attrs.map((attr: any) => (
                                                <label
                                                    key={attr.attributeId}
                                                    style={{
                                                        marginLeft: '10px',
                                                        padding: '8px 15px',
                                                        border: '1px solid #ddd',
                                                        borderRadius: '10px',
                                                        backgroundColor: currentVariant?.attributeIds.includes(attr.attributeId) ? '#007bff' : '#fff',
                                                        color: currentVariant?.attributeIds.includes(attr.attributeId) ? '#fff' : '#000',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s, color 0.3s',
                                                        textAlign: 'center',
                                                        minWidth: '100px',
                                                        maxWidth: '150px',
                                                        marginBottom: '10px'
                                                    }}
                                                    onClick={() => {
                                                        // const newVariant = product.product_variant?.find((v: any) => v.attributeIds.includes(attr.attributeId));
                                                        const newVariant = product.product_variant?.find((v: ProductVariant) => v.attributeIds.includes(attr.attributeId));
                                                        if (newVariant) {
                                                            handleVariantChange(newVariant);
                                                        }
                                                    }}
                                                >
                                                    {attr.attribute_name}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {/* <Box margin={"20px 0"}>
                                <Button variant="outlined" startIcon={<CompareArrowsIcon />} >
                                    Compare
                                </Button>
                                <Button variant="outlined" startIcon={<ShareIcon />} style={{ marginLeft: 8 }}>
                                    Share
                                </Button>
                            </Box> */}
                            <Divider />

                            <Box mt={2} display={'flex'}>
                                <TextField label="City" variant="outlined" size="small" color='primary' />
                                <Button variant="contained" style={{ backgroundColor: "#00AFCA", marginLeft: 8 }}>
                                    Check Availability
                                </Button>
                            </Box>

                            <Box display="flex" alignItems="center" margin={"20px 0"}>
                                <Button variant="outlined" onClick={decrement} sx={{ minWidth: '30px' }}>-</Button>
                                <TextField
                                    value={count}
                                    inputProps={{ readOnly: true, style: { textAlign: 'center', padding: "7px 0" } }}
                                    sx={{ width: '50px', margin: '0 10px' }}
                                />
                                <Button variant="outlined" onClick={increment} sx={{ minWidth: '30px' }}>+</Button>
                            </Box>

                            <Box margin={"20px 0"} display={'flex'}>
                                <Button variant="contained" style={{ backgroundColor: "#00AFCA" }}
                                    onClick={() => {
                                        handleClick(product, currentVariant);
                                    }}
                                >
                                    Add Cart
                                </Button>
                                {/* <Button variant="contained" style={{ backgroundColor: "#00AFCA" }} startIcon={<WhatsAppIcon />} style={{ marginLeft: 8 }}>
                                    Order From WhatsApp
                                </Button> */}
                            </Box>

                            <Divider />

                            <Box margin={"20px 0"}>
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
                                    {
                                        product.warranty_period === "NA" ?
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center"
                                            }}>
                                                <img src="https://res.cloudinary.com/dlrl6ra82/image/upload/v1718625119/icons/pbbgqapmvowy3hhfunjv.png" alt="no-warranty" style={{ width: "35px" }} />
                                                <Typography style={{ fontSize: "small", fontFamily: '"Poppins", sans-serif' }}>No Warranty</Typography>
                                            </div> :
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center"
                                            }}>
                                                <img src="https://res.cloudinary.com/dlrl6ra82/image/upload/v1718625119/icons/r8potp6twv5yhfhiqcjn.png" alt="warranty" style={{ width: "35px" }} />
                                                <Typography style={{ fontSize: "small", fontFamily: '"Poppins", sans-serif' }}>{product.warranty_period} Warranty</Typography>
                                            </div>
                                    }
                                    {
                                        product.cod_allowed ?
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center"
                                            }}>
                                                <img src="https://res.cloudinary.com/dlrl6ra82/image/upload/v1718625118/icons/klguymnjeyviuzvo8z4p.png" alt="COD" style={{ width: "35px" }} />
                                                <Typography style={{ fontSize: "small", fontFamily: '"Poppins", sans-serif' }}>COD Avilable</Typography>
                                            </div> :
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center"
                                            }}>
                                                <img src="https://res.cloudinary.com/dlrl6ra82/image/upload/v1718625118/icons/zrd8inaelfxxxu8maicn.png" alt="CODNOT" style={{ width: "35px" }} />
                                                <Typography style={{ fontSize: "small", fontFamily: '"Poppins", sans-serif' }}>COD Not Avilable</Typography>
                                            </div>
                                    }
                                    {
                                        product.is_returnable ?
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center"
                                            }}>
                                                <img src="https://res.cloudinary.com/dlrl6ra82/image/upload/v1718625119/icons/oxavfaogsdmk2lnvworf.png" alt="COD" style={{ width: "35px" }} />
                                                <Typography style={{ fontSize: "small", fontFamily: '"Poppins", sans-serif' }}>Returnable</Typography>
                                            </div> :
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center"
                                            }}>
                                                <img src="https://res.cloudinary.com/dlrl6ra82/image/upload/v1718625119/icons/vc4aeh1fk5n47l0hkznj.png" alt="CODNOT" style={{ width: "35px" }} />
                                                <Typography style={{ fontSize: "small", fontFamily: '"Poppins", sans-serif' }}>Non Returnable</Typography>
                                            </div>
                                    }
                                    {
                                        product.is_cancelable ?
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center"
                                            }}>
                                                <img src="https://res.cloudinary.com/dlrl6ra82/image/upload/v1718625118/icons/qmcoue3w8evrbqfkgg4f.png" alt="COD" style={{ width: "35px" }} />
                                                <Typography style={{ fontSize: "small", fontFamily: '"Poppins", sans-serif' }}>Cancelable</Typography>
                                            </div> :
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center"
                                            }}>
                                                <img src="https://res.cloudinary.com/dlrl6ra82/image/upload/v1718625118/icons/qmcoue3w8evrbqfkgg4f.png" alt="Not Cancelable" style={{ width: "35px" }} />
                                                <Typography style={{ fontSize: "small", fontFamily: '"Poppins", sans-serif' }}>Non Cancelable</Typography>
                                            </div>
                                    }
                                </div>
                            </Box>
                            <Divider />
                            {
                                product.description && (
                                    <Box>
                                        <div dangerouslySetInnerHTML={{ __html: product.description }} />
                                    </Box>
                                )
                            }
                        </Grid>
                    </Grid>
                )
            }


            <Box mt={4}>
                <Typography variant="h5" style={{ marginBottom: "20px" }}>Customer Reviews</Typography>
                {productReview && productReview.length === 0
                    ?
                    (
                        <p>0 Review</p>
                    )
                    : (productReview.map((review: any, index: any) => (
                        <Card key={index} variant="outlined" style={{ marginBottom: 16 }}>
                            <CardContent>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant="subtitle1">{review.user}</Typography>
                                    <Typography variant="body2" color="textSecondary">{review.createAt}</Typography>
                                </div>
                                <Rating value={Number(review.rating)} readOnly />
                                <Typography variant="body1">{review.comment}</Typography>
                            </CardContent>
                        </Card>
                    )))
                }
            </Box>

            <Box mt={4}>
                <Typography variant="h5">Related Products</Typography>
                <Grid container spacing={4}>
                    {relatedProducts.map((product: any, index: any) => (
                        <Grid item xs={12} sm={3} key={index}>
                            {/* <Card>
                                <CardMedia
                                    component="img"
                                    image={relatedProduct.main_image}
                                    alt={relatedProduct.name}
                                />
                                <CardContent>
                                    <Typography variant="body1">{relatedProduct.product_name}</Typography>
                                    <Typography variant="body2" color="textSecondary">{relatedProduct.price}</Typography>
                                    <Button variant="contained" style={{ backgroundColor: "#00AFCA" }} startIcon={<AddShoppingCartIcon />}>
                                        Add to Cart
                                    </Button>
                                </CardContent>
                            </Card> */}
                            <Card
                                style={{
                                    border: "1px solid #ddd",
                                    borderRadius: "8px",
                                    padding: "10px",
                                    width: "220px",
                                    textAlign: "center",
                                    margin: "20px 0px"
                                }}
                            >
                                <Link to={`/products/${product._id}`} style={{ textDecoration: "none", color: "black" }}>
                                    <img style={{ width: "200px", height: "200px", borderRadius: "8px" }} src={product.main_image} alt={product.name} />
                                    <div style={{ height: "50px" }}>
                                        <h5 style={{ margin: "10px 0" }}>{product.product_name}</h5>
                                    </div>
                                    <div style={{ marginTop: "5px", color: "gold" }}>
                                        <Rating name="size-medium" defaultValue={0} value={product.rating} readOnly />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <p style={{ color: "#ff0000" }}>₹{product.price ?? product.product_variant[0].price}</p>
                                        {(product.special_price ?? product.product_variant[0].special_price) &&
                                            <p style={{ textDecoration: "line-through" }}>₹{product.special_price ?? product.product_variant[0].special_price}</p>
                                        }
                                        {<p style={{ color: "green" }}>{
                                            Math.round((((product.price ?? product.product_variant[0].price) - (product.special_price ?? product.product_variant[0].special_price)) / (product.price ?? product.product_variant[0].price)) * 100)
                                        }% off</p>}
                                    </div>
                                </Link>

                                <button style={{
                                    // backgroundColor: "#6200ea",
                                    backgroundColor: "#00AFCA",
                                    color: "white",
                                    border: "none",
                                    padding: "10px",
                                    borderRadius: "8px",
                                    cursor: "pointer"
                                }}>
                                    Add to Cart
                                </button>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    )
}

export default ProductDetails;