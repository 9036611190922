import { Divider, List, ListItem, ListItemText, Paper } from '@mui/material';
import menuItems from '../constants/menuItems';
import { useNavigate } from 'react-router-dom';
import { signOutSuccess } from '../store/userSlice';
import { useDispatch } from 'react-redux';

function UserSidebar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = (event: any, page: any, route: any) => {
        console.log(" --  :route: -- :: ", route);
        if (page === "Logout") {
            localStorage.removeItem("authToken");
            dispatch(signOutSuccess());
        }
        navigate(route);
    }

    return (
        <>
            <Paper style={{ width: "20%" }}>
                <List style={{ paddingTop: "0px" }}>
                    <h2>My Account</h2>
                    <Divider />
                    {menuItems.map((item, index) => (
                        <ListItem button key={index}>
                            {item.icon}
                            <ListItemText primary={item.text} sx={{ ml: 2 }}
                                onClick={(event) => {
                                    handleClick(
                                        event,
                                        item.text,
                                        item.route ? item.route : "/"
                                    );
                                }} />
                        </ListItem>
                    ))}
                </List>
            </Paper>
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
        </>
    )
}

export default UserSidebar;