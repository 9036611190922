import { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useNavigate } from 'react-router-dom';
import { Drawer, Divider, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import GoogleIcon from '@mui/icons-material/Google';
// import FacebookIcon from '@mui/icons-material/Facebook';
import { useDispatch } from 'react-redux';
import { postRequest } from '../Api/api';
import { signInSuccess, signInFailure, signOutSuccess } from "../store/userSlice";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Cart from '../pages/Cart';
import pages from '../constants/pages';
import settings from '../constants/userOption';

function NavBar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [open, setOpen] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState(false);
    const [loginMsg, setLoginMsg] = useState('');
    const [cartLength, setCartLength] = useState(0);
    const [wishlistLength, setWishlistLength] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event: any) => {
        const isloggedIn = localStorage.getItem("authToken");
        if (isloggedIn) {
            setAnchorElUser(event.currentTarget);
        } else {
            setOpen(true);
        }
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleClick = (event: any, page: any, route: any) => {
        console.log(" --  :route: -- :: ", route);
        navigate(route);
    }

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleLogin = async () => {
        try {
            const data = {
                mobile: mobileNumber,
                password: password,
            }
            const response = await postRequest("sign_in", navigate, dispatch, data);
            // console.log("handleLogin :: response :: ", response);
            localStorage.setItem("authToken", response.data.token);
            // dispatch(signInSuccess(response));
            dispatch(signInSuccess(response));
            setOpen(false);
            setLoginMsg(response.message);
            setMsg(true);
        } catch (error) {
            setLoginMsg("Something went wrong!");
            dispatch(signInFailure("Something went wrong!"));
            console.error("handleLogin :: Error: ", error);
        }
    }

    const handleLogout = () => {
        handleCloseUserMenu();
        localStorage.removeItem("authToken");
        dispatch(signOutSuccess());
        setMsg(true);
        setLoginMsg("User logout successfully");
    };

    const handleWishlist = async () => {
        const user = localStorage.getItem("authToken");
        if (!user) {
            setOpen(true);
        } else {
            const response = await postRequest("get_favorites", navigate, dispatch);
            console.log("fetchWishlist :: response :: ", response.data.length);
            setWishlistLength(response.data.length);
            navigate("/wishlist");
        }
    }

    useEffect(() => {
        const fetchWishlist = async () => {
            const user = localStorage.getItem("authToken");
            if (user) {
                const response = await postRequest("get_favorites", navigate, dispatch);
                console.log("fetchWishlist :: response :: ", response.data.length);
                setWishlistLength(response.data.length);

            }
        }

        fetchWishlist();
        window.addEventListener("wishlistUpdated", fetchWishlist);

        const updateCartLength = async () => {
            const user = localStorage.getItem("authToken");
            if (user) {
                const response = await postRequest("get_user_cart", navigate, dispatch);
                console.log("updateCartLength :: response :: ", response);
                setCartLength(response.data.length);

            } else {
                const cartItems = localStorage.getItem("cart");
                if (cartItems) {
                    const parsedCartItems = JSON.parse(cartItems);
                    setCartLength(parsedCartItems.length);
                }
            }

        };

        updateCartLength();
        window.addEventListener("cartUpdated", updateCartLength);

        return () => {
            window.removeEventListener("cartUpdated", updateCartLength);
            window.removeEventListener("wishlistUpdated", fetchWishlist);
        };
        // eslint-disable-next-line
    }, []);

    //#1782bd
    return (
        <>
            <AppBar position="static" sx={{ backgroundColor: "#00AFCA" }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>

                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((e: any) => (
                                    <MenuItem
                                        key={e.name}
                                        onClick={(event) => {
                                            handleClick(
                                                event,
                                                e.name,
                                                e.route ? e.route : "/"
                                            );
                                            handleCloseNavMenu();
                                        }}
                                    >
                                        <Typography textAlign="center">{e.name}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((e) => (
                                <Button
                                    key={e.name}
                                    // onClick={handleCloseNavMenu}
                                    onClick={(event) => {
                                        handleClick(
                                            event,
                                            e.name,
                                            e.route ? e.route : "/"
                                        );
                                    }}
                                    sx={{ my: 1, mx: 1, color: 'white', display: 'block', fontSize: "medium", fontFamily: '"Poppins", sans-serif' }}
                                >
                                    {e.name}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }} >

                            <IconButton size="large" color="inherit" onClick={toggleDrawer}>
                                <Badge badgeContent={cartLength} color="error">
                                    <ShoppingCartIcon />
                                </Badge>
                            </IconButton>

                            <Drawer
                                anchor='right'
                                open={isDrawerOpen}
                                onClose={toggleDrawer}
                            >
                                <Box
                                    sx={{ width: 300, p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}
                                    role="presentation"
                                >
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6">Shopping Cart</Typography>
                                        <IconButton onClick={toggleDrawer}>
                                            <CloseIcon fontSize='small' />
                                        </IconButton>
                                    </Box>
                                    <Divider />
                                    <Cart toggleDrawer={toggleDrawer} />
                                </Box>
                            </Drawer>

                            <IconButton size="large" color="inherit" onClick={handleWishlist}>
                                <Badge badgeContent={wishlistLength} color="error">
                                    <FavoriteBorderRoundedIcon />
                                </Badge>
                            </IconButton>


                            <Tooltip title="Open settings">
                                <IconButton size="large" color="inherit" onClick={handleOpenUserMenu}>
                                    <AccountCircleOutlinedIcon />
                                </IconButton>
                            </Tooltip>

                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting.name} onClick={`${setting.name}` === "Logout" ? handleLogout : handleCloseUserMenu}>
                                        <Typography textAlign="center" onClick={(event) => {
                                            handleClick(
                                                event,
                                                setting.name,
                                                setting.route ? setting.route : "/"
                                            );
                                        }}>{setting.name}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>

                            <Drawer
                                anchor="right"
                                open={open}
                                onClose={() => setOpen(false)}
                            >
                                <Box
                                    sx={{ width: 350, padding: 2 }}
                                    role="presentation"
                                >
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6">Register</Typography>
                                        <IconButton onClick={() => setOpen(false)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ my: 2 }} />
                                    <TextField
                                        label="Mobile Number"
                                        type="tel"
                                        fullWidth
                                        margin="normal"
                                        required
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                    />
                                    <TextField
                                        label="Password"
                                        type="password"
                                        fullWidth
                                        margin="normal"
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <Button variant="contained" style={{ backgroundColor: "#00AFCA" }} fullWidth sx={{ mt: 2 }} onClick={handleLogin}>
                                        Login
                                    </Button>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
                                        <Box>
                                            <Button variant="text" style={{ color: "#00AFCA" }}>Forgot Password?</Button>
                                        </Box>
                                        <Box>
                                            <Button variant="text" style={{ color: "#00AFCA" }}>Remember Me</Button>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box display="flex" justifyContent="center" mt={2}>
                                        <Button variant="text" style={{ color: "#00AFCA" }}>Create New Account</Button>
                                    </Box>
                                </Box>
                            </Drawer>

                            <Snackbar open={msg} autoHideDuration={6000} onClose={() => setMsg(false)}>
                                <Alert
                                    onClose={() => setMsg(false)}
                                    severity="success"
                                    variant="filled"
                                    sx={{ width: '100%' }}
                                >
                                    {loginMsg}
                                </Alert>
                            </Snackbar>

                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    )
}

export default NavBar;