import { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Container, Drawer, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getRequest } from '../Api/api';
import Sidebar from '../components/Sidebar';
import ProductCard from './ProductCard';
import "./index.css";

function Product() {
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [product, setProduct] = useState<any[]>([]);
    const [message, setMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
    const [attribute, setAttribute] = useState<any[]>([]);
    const [expanded, setExpanded] = useState<string | false>(false);

    const fetchProduct = async () => {
        setLoading(true);
        try {
            let url = "getAllProduct";
            if (location.state && location.state.id) {
                url = `getAllProduct?subCategory_id=${location.state.id}`;
            } else if (params && params.id) {
                url = `getAllProduct?brand_id=${params.id}`;
            }

            const filterParams = new URLSearchParams();
            Object.keys(selectedFilters).forEach((key) => {
                selectedFilters[key].forEach(value => {
                    filterParams.append("attribute_id", value);
                });
            });
            if (filterParams.toString()) {
                url += `?${filterParams.toString()}`;
            }

            const response = await getRequest(url, navigate, dispatch);
            // console.log("response: :: ", response);
            if (response.status === 200) {
                if (response.data.length !== 0) {
                    setProduct(response.data.map((e: any) => ({
                        id: e._id,
                        name: e.product_name,
                        description: e.short_description,
                        image: e.main_image,
                        price: e.price ?? e.product_variant[0].price,
                        special_price: e.special_price ?? e.product_variant[0].special_price,
                        rating: e.rating ?? 0,
                        no_of_rating: e.no_of_rating ?? 0
                    })));
                } else {
                    setMessage(response.message);
                    setProduct([]);
                }
            } else {
                console.log(response.message);
                setMessage(response.message);
                setProduct([]);
                // navigate("/products");
            }
        } catch (error: any) {
            console.log(error);
            setMessage(error);
        }
        setLoading(false);
    }

    const handleClick = () => {
        navigate("/");
    }

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    const clearFilters = () => {
        setSelectedFilters({});
        fetchProduct();
        closeDrawer();
    };

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const fetchAttributeSet = async () => {
        try {
            const details = await getRequest("getAttribute", navigate, dispatch);
            // console.log("details :: ", details);
            setAttribute(details.data);
        } catch (error) {
            console.error('Error fetching attributes:', error);
        }
    }

    const handleFilterChange = (attributeId: string, valueId: string) => {
        setSelectedFilters((prevFilters) => {
            const newFilters = { ...prevFilters };
            if (!newFilters[attributeId]) {
                newFilters[attributeId] = [];
            }

            if (newFilters[attributeId].includes(valueId)) {
                newFilters[attributeId] = newFilters[attributeId].filter(id => id !== valueId);
            } else {
                newFilters[attributeId].push(valueId);
            }

            if (newFilters[attributeId].length === 0) {
                delete newFilters[attributeId];
            }
            return newFilters;
        });
    };

    useEffect(() => {
        fetchAttributeSet();
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        fetchProduct();
        // eslint-disable-next-line
    }, [selectedFilters]);

    return (
        <>
            <Container style={{ marginBottom: "20px" }}>
                <Typography variant="h5" fontWeight={700} sx={{ m: 4 }} style={{ color: "#00AFCA" }}>
                    Product Listing
                </Typography>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer(true)}
                    sx={{ display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>

                <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                    sx={{ display: { xs: 'block', sm: 'none' }, width: "100%" }}
                >
                    <Sidebar
                        attribute={attribute}
                        expanded={expanded}
                        handleChange={handleChange}
                        handleFilterChange={handleFilterChange}
                        selectedFilters={selectedFilters}
                        clearFilters={clearFilters}
                        closeDrawer={closeDrawer}
                    />
                </Drawer>

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                        <CircularProgress style={{ color: "#00AFCA" }} />
                    </Box>
                ) : (
                    <div className="content-container">
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <Sidebar
                                attribute={attribute}
                                expanded={expanded}
                                handleChange={handleChange}
                                handleFilterChange={handleFilterChange}
                                selectedFilters={selectedFilters}
                                clearFilters={clearFilters}
                                closeDrawer={closeDrawer}
                            />
                        </Box>
                        <div className="product-list">
                            {product.length !== 0 ? (
                                <div className="product-grid">
                                    {product.map((product: any) => (
                                        <ProductCard key={product.id} product={product} />
                                    ))}
                                </div>
                            ) : (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="70vh"
                                    textAlign="center"
                                >
                                    <Typography variant="h6" color="black" gutterBottom fontWeight={500}>
                                        {message}
                                    </Typography>
                                    <Button variant="contained" style={{ backgroundColor: "#00AFCA" }} onClick={handleClick}>
                                        Go to Shop
                                    </Button>
                                </Box>
                            )}
                        </div>
                    </div>
                )}
            </Container>
        </>
    )
}

export default Product;