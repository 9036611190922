const settings = [
    {
        name: "Dashboard",
        route: "/dashboard",
    },
    {
        name: "Orders",
        route: "/orders",
    },

    {
        name: "Address",
        route: "/address",
    },
    {
        name: "Account Details",
        route: "/account-details",
    },
    {
        name: "Wishlist",
        route: "/wishlist",
    },
    {
        name: "Customer Support",
        route: "/customer-support",
    },
    {
        name: "Wallet",
        route: "/wallet",
    },
    {
        name: "Refer And Earn",
        route: "/refer-earn",
    },
    {
        name: "Logout"
    }
];

export default settings;