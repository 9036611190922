import { Container, Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import UserSidebar from '../components/UserSidebar';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { postRequest } from '../Api/api';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Transactions() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await postRequest("transactions", navigate, dispatch, {
                transaction_type: "transaction"
            });
            // console.log("fetchData :: ", response);
            setData(response.data);
        } catch (error) {
            console.log("fetchData :: error :: ", error);
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    // update page count
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (
        <Container maxWidth="lg">
            <Box display="flex" mt={3}>
                <UserSidebar />
                <Box sx={{ flexGrow: 1, p: 2 }}>
                    <Typography variant="h5" gutterBottom style={{ paddingTop: "10px", fontWeight: "600" }}>
                        Transactions
                    </Typography>

                    <Paper sx={{ width: '100%', overflow: 'hidden', mt: 2 }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Id</TableCell>
                                        <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>User Name</TableCell>
                                        <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Order Id</TableCell>
                                        <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Transaction Id</TableCell>
                                        <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Amount</TableCell>
                                        <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Status</TableCell>
                                        <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.length !== 0 ? (
                                            <>
                                                {data
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row: any, i: any) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{i + 1}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.user_name}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.order}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.txn_id}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.amount}</TableCell>
                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.status}</TableCell>

                                                                <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{format(new Date(row.createAt), 'dd-MM-yyyy h:mm a')}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </>
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={6} style={{ textAlign: 'center', fontSize: "18px", fontFamily: '"Poppins", sans-serif' }}>
                                                    No matching records found
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            </Box>
        </Container>
    )
}

export default Transactions;