import React, { useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel';
import "./home.css";
import { Alert, Box, Button, Card, CardMedia, Container, Grid, IconButton, Rating, Snackbar, Typography } from '@mui/material';
import CommonCard from './CommonCard';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getRequest, postRequest } from '../Api/api';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

interface FavoritesState {
    [key: string]: boolean;
}

function Home() {
    const [index, setIndex] = React.useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [category, setCategory] = useState([]);
    const [brand, setBrand] = useState([]);
    const [section, setSection] = useState([]);

    const fetchSlider = async () => {
        try {
            const response = await getRequest("get_slider", navigate, dispatch);
            setItems(response.data);
        } catch (error) {
            console.log("fetchSlider :: error :: ", error);
        }
    }

    const fetchCategory = async () => {
        try {
            const response = await getRequest("getAllCategory", navigate, dispatch);
            setCategory(
                response.data.map((e: { _id: any; name: any; image: any; }) => {
                    return { id: e._id, title: e.name, cover: e.image };
                })
            );
        } catch (error) {
            console.log("fetchCategory :: error :: ", error);
        }
    }

    const fetchBrand = async () => {
        try {
            const response = await getRequest("getBrand", navigate, dispatch);
            setBrand(
                response.data.map((e: { _id: any; name: any; image: any; }) => {
                    return { id: e._id, title: e.name, cover: e.image };
                })
            );
        } catch (error) {
            console.log("fetchBrand :: error :: ", error);
        }
    }

    const fetchSection = async () => {
        try {
            const response = await postRequest("get_sections", navigate, dispatch);
            console.log("response :: ", response);
            if (response.status === 200) {
                setSection(response.data);
            }
        } catch (error) {
            console.log("fetchSection :: error :: ", error);
        }
    }

    const handleChange = (cur?: any, prev?: any) => {
        setIndex(cur);
        // console.log(cur, prev);
    };

    useEffect(() => {
        fetchSlider();
        fetchCategory();
        fetchBrand();
        fetchSection();
        // eslint-disable-next-line
    }, []);

    let user = localStorage.getItem("authToken");
    const [favorites, setFavorites] = useState<FavoritesState>({});
    const [snack, setSnack] = useState(false);
    const [message, setMessage] = useState('');

    const handleClick = async (event: any) => {
        console.log("event ::", event);
        let product = {
            product_id: event.id,
            name: event.name,
            description: event.description,
            image: event.image,
            price: event.variants[0].price,
            special_price: event.variants[0].special_price
        }

        if (!user && user === null) {
            let cart_data = localStorage.getItem("cart");
            console.log("cart_data :: ", cart_data);
            let newData = cart_data ? JSON.parse(cart_data) : [];
            newData.push(product);
            console.log("newData :: ", newData);

            localStorage.setItem("cart", JSON.stringify(newData));
            window.dispatchEvent(new Event("cartUpdated"));
        } else {
            // product_id, product_variant_id, user_id, qty
            const response = await postRequest("manage_cart", navigate, dispatch, {
                product_id: event.id,
                qty: 1
            });
            console.log("reponse :: ", response);
            window.dispatchEvent(new Event("cartUpdated"));
        }
    }

    const handleFav = async (e: any) => {
        console.log("e :: e :: ", e);
        try {
            if (!user && user === null) {
                setSnack(true);
                setMessage("Login First to Add Products in Favorite List.");
            } else {
                const response = await postRequest("add_to_favorites", navigate, dispatch, {
                    product_id: e
                });
                console.log("response :: ", response);
                setFavorites((prevFavorites) => ({
                    ...prevFavorites,
                    [e.id]: true
                }));
                window.dispatchEvent(new Event("wishlistUpdated"));
            }

        } catch (error) {
            console.log('handleFav :: error :: ', error);
        }
    }

    return (
        <>
            <Container maxWidth="xl" sx={{ mb: 4 }}>
                <Box mt={4}>
                    <Carousel
                        index={index}
                        onChange={() => handleChange()}
                        interval={4000}
                        animation="slide"
                        indicators={false}
                        stopAutoPlayOnHover
                        swipe
                        className="my-carousel"
                    >
                        {items.map((item: any, i: any) => (
                            <Box key={i} sx={{ width: '100%', height: '100%', borderRadius: 2 }}>
                                <img
                                    src={item.image}
                                    alt={item.slider_type}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        border: '10px',
                                        objectFit: 'cover',
                                        borderRadius: '20px',
                                    }}
                                />
                            </Box>
                        ))}
                    </Carousel>
                </Box>

                {/* Popular Categories */}
                <Box mt={4}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h5" sx={{ fontSize: { xs: '20px', sm: '25px' }, color: "#00AFCA", fontWeight: '600' }}>Popular Categories</Typography>
                        <Link to={"/category"}>
                            <Button variant="contained" sx={{ background: '#00AFCA' }}>View More</Button>
                        </Link>
                    </Box>
                    <Grid container justifyContent="center" spacing={2}>
                        {category.map((e: any) => (
                            <Grid item key={e.id} xs={6} sm={4} md={3} lg={2}>
                                <CommonCard post={e} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* Brands */}
                <Box mt={4}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h5" sx={{ fontSize: { xs: '20px', sm: '25px' }, color: "#00AFCA", fontWeight: '600' }}>Brands</Typography>
                        <Link to={"/brand"}>
                            <Button variant="contained" sx={{ background: '#00AFCA' }}>See All</Button>
                        </Link>
                    </Box>
                    <Grid container justifyContent="center" spacing={3}>
                        {brand.slice(0, 6).map((e: any) => (
                            <Grid item key={e.id} xs={6} sm={4} md={3} lg={2}>
                                <Link to={`/products/${e.id}`}>
                                    <Box
                                        sx={{
                                            margin: "5px",
                                            padding: "10px",
                                            height: { xs: "80px", sm: "120px" },
                                            position: 'relative',
                                            overflow: 'hidden',
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            '&:hover img': {
                                                transform: 'scale(1.2)',
                                            },
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            src={e.cover}
                                            alt={e.title}
                                            sx={{
                                                height: { xs: "80px", sm: "120px" },
                                                objectFit: 'contain',
                                                width: "200px",
                                                transition: 'transform 0.3s ease-in-out'
                                            }}
                                        />
                                    </Box>
                                </Link>
                                <Box sx={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold', mt: 1 }}>{e.title}</Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* Featured Section */}
                <Box mt={4}>
                    {section.map((e: any) => (
                        <Box key={e._id}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "4%", mb: 2 }}>
                                <Typography variant="h5" sx={{ fontSize: { xs: '15px', sm: '25px' }, color: "#00AFCA", fontWeight: '600' }}>{e.title}</Typography>
                                <Link to={"/products"}>
                                    <Button variant="contained"
                                        sx={{
                                            background: '#00AFCA',
                                            padding: { xs: '5px 10px', sm: '10px 20px' },
                                            fontSize: { xs: '12px', sm: '16px' }
                                        }}
                                    >
                                        View More
                                    </Button>
                                </Link>
                            </Box>
                            <Grid container justifyContent="center" spacing={3} sx={{ flexWrap: "wrap", gap: "30px" }}>
                                {e.product_details.map((product: any) => (
                                    <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
                                        <Card
                                            sx={{
                                                border: "1px solid #ddd",
                                                borderRadius: 1,
                                                p: 2,
                                                textAlign: "center",
                                                position: "relative",
                                                // height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <IconButton
                                                sx={{
                                                    position: "absolute",
                                                    top: 1,
                                                    left: 1,
                                                    color: favorites[product.id] ? "red" : "black"
                                                }}
                                                onClick={() => handleFav(product)}
                                            >
                                                {favorites[product.id] ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                            </IconButton>

                                            <Link to={`/productDetails/${product.id}`} style={{ textDecoration: "none", color: "black" }}>
                                                <img
                                                    src={product.image}
                                                    alt={product.name}
                                                    style={{ width: 200, height: 200, borderRadius: 1 }}
                                                />
                                                <Box sx={{ height: 50, mt: 1 }}>
                                                    <Typography variant="subtitle1">{product.name}</Typography>
                                                </Box>
                                                <Box mt={1} color="gold">
                                                    <Rating name="size-medium" value={Number(product.rating)} readOnly />
                                                </Box>
                                                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                                                    <Typography variant="body2" sx={{ color: "#ff0000" }}>₹{product.variants[0].special_price}</Typography>
                                                    {product.variants[0].price && (
                                                        <Typography variant="body2" sx={{ textDecoration: "line-through" }}>
                                                            ₹{product.variants[0].price}
                                                        </Typography>
                                                    )}
                                                    <Typography variant="body2" sx={{ color: "green" }}>
                                                        {Math.round(((product.variants[0].price - product.variants[0].special_price) / product.variants[0].price) * 100)}% off
                                                    </Typography>
                                                </Box>
                                            </Link>

                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "#00AFCA",
                                                    color: "white",
                                                    mt: 1,
                                                    py: 1,
                                                    borderRadius: 1,
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => handleClick(product)}
                                            >
                                                Add to Cart
                                            </Button>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    ))}
                </Box>


            </Container>

            <Snackbar
                open={snack}
                autoHideDuration={3000}
                onClose={() => setSnack(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert
                    onClose={() => setSnack(false)}
                    severity="info"
                    // variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Home;