import { useEffect, useState } from 'react';
import { postRequest } from '../Api/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Container, Typography, Grid, Card, CardMedia, CardContent } from '@mui/material';

interface TimeLeft {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

function Offer() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [sale, setSale] = useState([]);
    const [endDate, setEndDate] = useState<string | null>(null);

    const fetchSale = async () => {
        try {
            const response = await postRequest("get_flash_sale", navigate, dispatch);
            // console.log("fetchSale ::  ressponse :: ", response);
            setEndDate(response.data.end_date);
            setSale(response.data);
        } catch (error) {
            console.log("fetchSale :: error :: ", error);
        }
    }

    const fetchOffer = async () => {
        try {
            const response = await postRequest("get_offer_images", navigate, dispatch);
            console.log("fetchOffer :: response :: ", response);

        } catch (error) {
            console.log("fetchOffer :: error :: ", error);
        }
    }

    const calculateTimeLeft = (endDate: string | null): TimeLeft => {
        const difference = endDate ? +new Date(endDate) - +new Date() : 0;
        let timeLeft: TimeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    // eslint-disable-next-line
    const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft(endDate));

    useEffect(() => {
        // const timer = setInterval(() => {
        // }, 1000);
        setTimeLeft(calculateTimeLeft(endDate));
        // eslint-disable-next-line

        // return () => clearInterval(timer);
    }, [endDate]);

    useEffect(() => {
        fetchSale();
        fetchOffer();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                sale.length !== 0 && (

                    <Box
                        mt={4}
                        mb={4}
                        sx={{
                            background: 'radial-gradient(circle, rgba(242, 130, 219, 0.908000700280112) 40%, rgba(112, 176, 252, 0.8939950980392157) 100%)',
                            minHeight: '70vh',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {
                            sale.map((e: any) => (
                                <Container>
                                    <Typography variant="h3" component="div" align="center" gutterBottom>
                                        {e.title}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginY: 2,
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {['2 DAYS', '7 HR', '49 MIN', '38 SC'].map((time, index) => (
                                                <Box key={index} sx={{ mx: 2, textAlign: 'center' }}>
                                                    <Typography variant="h5">{time.split(' ')[0]}</Typography>
                                                    <Typography variant="subtitle1">{time.split(' ')[1]}</Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                    <Grid container justifyContent="center" spacing={2}>
                                        {
                                            e.products.length !== 0 && (
                                                e.products.map((p: any) => (
                                                    <Grid item>
                                                        <Card sx={{ maxWidth: 300 }}>
                                                            <CardMedia
                                                                component="img"
                                                                alt={p.title}
                                                                height="140"
                                                                image={p.image}
                                                            />
                                                            <CardContent>
                                                                <Typography gutterBottom variant="h5" component="div">
                                                                    {p.name}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    {e.short_description}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    Sale {e.discount}%
                                                                </Typography>
                                                                {/* <Typography variant="h6">$3150</Typography>
                                                        <Button variant="contained" style={{ backgroundColor: "#00AFCA" }} fullWidth>
                                                            Add to Cart
                                                        </Button> */}
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                ))

                                            )
                                        }
                                    </Grid>

                                </Container>
                            ))
                        }

                    </Box>
                )
            }
            <Container style={{ marginBottom: "20px" }}>
                <Typography variant="h5" fontWeight={700} sx={{ m: 4 }} style={{ color: "#00AFCA" }}>
                    Flash Sale
                </Typography>

            </Container>
        </>

    )
}

export default Offer;