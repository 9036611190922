import { Container, Typography, Button, Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import icon from "../assets/wired-flat-1103-confetti.gif";

const OrderSuccess = () => {
    const navigate = useNavigate();
    window.dispatchEvent(new Event("cartUpdated"));

    return (
        <Container maxWidth="md" sx={{ mt: 8, mb: 4 }}>
            <Paper elevation={3} sx={{ padding: 4 }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column">
                    <Grid item>
                        {/* <CheckCircleIcon sx={{ fontSize: 80, color: '#00AFCA' }} /> */}
                        <img src={icon} alt="success" />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4caf50' }}>
                            Order Placed Successfully
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ mt: 1, color: 'black', alignItems: "center" }}>
                            Thank you for your order! Your order has been placed successfully. You will receive a confirmation email shortly.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" style={{ backgroundColor: "#00AFCA" }} sx={{ mt: 4 }} onClick={() => navigate("/")}>
                            Continue Shopping
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>


    )
}

export default OrderSuccess;