import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../pages/Home";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import ContactUs from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";
import Category from "../pages/Category";
import Product from "../pages/Product";
import Offer from "../pages/Offer";
import SubCategory from "../pages/SubCategory";
import Faqs from "../pages/Faqs";
import ProductDetails from "../components/ProductDetails";
import DashBoard from "../pages/DashBoard";
import Orders from "../pages/Orders";
import Address from "../pages/Address";
import AccountDetails from "../pages/AccountDetails";
import Wishlist from "../pages/Wishlist";
import CustomerSupport from "../pages/CustomerSupport";
import Wallet from "../pages/Wallet";
import ReferEarn from "../pages/ReferEarn";
import Notification from "../pages/Notification";
import Transactions from "../pages/Transactions";
import DeleteAccount from "../pages/DeleteAccount";
import OrderDetails from "../pages/OrderDetails";
import ViewCart from "../pages/ViewCart";
import Checkout from "../pages/Checkout";
import OrderSuccess from "../components/OrderSuccess";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "",
                element: <Home />
            },
            {
                path: "login",
                element: <Login />
            },
            {
                path: "sign-up",
                element: <SignUp />
            },
            {
                path: "contact-us",
                element: <ContactUs />
            },
            {
                path: "about-us",
                element: <AboutUs />
            },
            {
                path: "faqs",
                element: <Faqs />
            },
            {
                path: "category",
                element: <Category />
            },
            {
                path: "subcategories/:id",
                element: <SubCategory />
            },
            {
                path: "products",
                element: <Product />
            },
            {
                path: "products/:id",
                element: <Product />
            },
            {
                path: "productDetails/:id",
                element: <ProductDetails />
            },
            {
                path: "offers",
                element: <Offer />
            },
            {
                path: "dashboard",
                element: <DashBoard />
            },
            {
                path: "orders",
                element: <Orders />
            },
            {
                path: "order-details/:id",
                element: <OrderDetails />
            },
            {
                path: "address",
                element: <Address />
            },
            {
                path: "account-details",
                element: <AccountDetails />
            },
            {
                path: "wishlist",
                element: <Wishlist />
            },
            {
                path: "customer-support",
                element: <CustomerSupport />
            },
            {
                path: "wallet",
                element: <Wallet />
            },
            {
                path: "refer-earn",
                element: <ReferEarn />
            },
            {
                path: "cart",
                element: <ViewCart />
            },
            {
                path: "checkout",
                element: <Checkout />
            },
            {
                path: "notification",
                element: <Notification />
            },
            {
                path: "transactions",
                element: <Transactions />
            },
            {
                path: "delete-account",
                element: <DeleteAccount />
            },
            {
                path: "success",
                element: <OrderSuccess />
            }
        ]
    }
]);

export default router;