import { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CategoriesCard from './CategoryCard';
import { getRequest } from '../Api/api';

function Category() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [category, setCategory] = useState([]);

    const fetchCategory = async () => {
        const response = await getRequest("getAllCategory", navigate, dispatch);
        setCategory(
            response.data.map((e: { _id: any; name: any; image: any; }) => {
                return { id: e._id, title: e.name, cover: e.image };
            })
        );
    }

    useEffect(() => {
        fetchCategory();
        // eslint-disable-next-line
    }, []);

    return (
        <Container sx={{ marginBottom: 4 }}>
            <Typography
                variant="h5"
                fontWeight={700}
                sx={{ m: { xs: 2, sm: 4 }, color: "#00AFCA" }}
            >
                Categories
            </Typography>
            <Grid container spacing={3}>
                {category.map((post: any) => (
                    <CategoriesCard key={post.id} post={post} />
                ))}
            </Grid>
        </Container>
    )
}

export default Category;