import React from 'react';
import {
    Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Collapse, Menu, MenuItem
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface AddressTableProps {
    address: any[];
    onEdit: (record: any) => void;
    onDelete: (record: any) => void;
}

const AddressTable: React.FC<AddressTableProps> = ({ address, onEdit, onDelete }) => {
    const [open, setOpen] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleRowClick = (rowId: any) => {
        setOpen(open === rowId ? null : rowId);
    };

    const handleMenuClick = (event: any, row: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(row);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleActionClick = (record: any, actionName: any) => {
        if (actionName === "Edit") {
            onEdit(record);
        } else if (actionName === "Delete") {
            onDelete(record);
        }
        handleMenuClose();
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Name</TableCell>
                        <TableCell align="right" style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Type</TableCell>
                        <TableCell align="right" style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Mobile Number</TableCell>
                        <TableCell align="right" style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Alternate Mobile</TableCell>
                        <TableCell align="right" style={{ fontSize: "18px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Action</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {address.length !== 0 ? (
                        address.map((row: any) => (
                            <React.Fragment key={row.id}>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <TableCell>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => handleRowClick(row.id)}
                                        >
                                            {open === row.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>

                                    <TableCell component="th" scope="row" style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>
                                        {row.name}
                                    </TableCell>

                                    <TableCell align="right" style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.type}</TableCell>
                                    <TableCell align="right" style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.mobile}</TableCell>
                                    <TableCell align="right" style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>{row.alternate_mobile}</TableCell>
                                    <TableCell align="right" style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif' }}>
                                        <IconButton aria-label="actions" onClick={(e) => handleMenuClick(e, row)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => handleActionClick(row, "Edit")}>
                                                Edit
                                            </MenuItem>
                                            <MenuItem onClick={() => handleActionClick(row, "Delete")} style={{ color: 'red' }}>
                                                Delete
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                                            <Box sx={{ margin: 1 }}>
                                                <Table size="small" aria-label="purchases">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Address</TableCell>
                                                            <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Area</TableCell>
                                                            <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>City</TableCell>
                                                            <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>State</TableCell>
                                                            <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Pincode</TableCell>
                                                            <TableCell style={{ fontSize: "15px", fontFamily: '"Poppins", sans-serif', fontWeight: "600" }}>Country</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableCell style={{ fontSize: "14px", fontFamily: '"Poppins", sans-serif' }}>{row.address}</TableCell>
                                                        <TableCell style={{ fontSize: "14px", fontFamily: '"Poppins", sans-serif' }}>{row.area}</TableCell>
                                                        <TableCell style={{ fontSize: "14px", fontFamily: '"Poppins", sans-serif' }}>{row.city}</TableCell>
                                                        <TableCell style={{ fontSize: "14px", fontFamily: '"Poppins", sans-serif' }}>{row.state}</TableCell>
                                                        <TableCell style={{ fontSize: "14px", fontFamily: '"Poppins", sans-serif' }}>{row.zipcode}</TableCell>
                                                        <TableCell style={{ fontSize: "14px", fontFamily: '"Poppins", sans-serif' }}>{row.country}</TableCell>
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={6} style={{ textAlign: 'center', fontSize: "18px", fontFamily: '"Poppins", sans-serif' }}>
                                No matching records found
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AddressTable;