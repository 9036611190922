import { Alert, Button, Card, Rating, Snackbar } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { postRequest } from '../Api/api';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useState } from 'react';
import "./index.css";

function ProductCard({ product }: any) {
    let user = localStorage.getItem("authToken");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [snack, setSnack] = useState(false);
    const [message, setMessage] = useState('');
    const [isFavorite, setIsFavorite] = useState(false);

    const handleClick = async (event: any) => {
        console.log("event ::", event);
        let product = {
            product_id: event.id,
            name: event.name,
            description: event.description,
            image: event.image,
            price: event.price,
            special_price: event.special_price
        }

        if (!user && user === null) {
            let cart_data = localStorage.getItem("cart");
            console.log("cart_data :: ", cart_data);
            let newData = cart_data ? JSON.parse(cart_data) : [];
            newData.push(product);
            console.log("newData :: ", newData);

            localStorage.setItem("cart", JSON.stringify(newData));
            window.dispatchEvent(new Event("cartUpdated"));
        } else {
            // product_id, product_variant_id, user_id, qty
            const response = await postRequest("manage_cart", navigate, dispatch, {
                product_id: event.id,
                qty: 1
            });
            console.log("reponse :: ", response);
            window.dispatchEvent(new Event("cartUpdated"));
        }
    }

    const handleFav = async (e: any) => {
        console.log("e :: e :: ", e);
        try {
            if (!user && user === null) {
                setSnack(true);
                setMessage("Login First to Add Products in Favorite List.");
            } else {
                const response = await postRequest("add_to_favorites", navigate, dispatch, {
                    product_id: e.id
                });
                console.log("response :: ", response);
                setIsFavorite(true);
                window.dispatchEvent(new Event("wishlistUpdated"));
            }

        } catch (error) {
            console.log('handleFav :: error :: ', error);
        }
    }

    return (
        <>
            <Card className="product-card">
                <IconButton
                    style={{ position: "absolute", top: "10px", left: "10px", color: isFavorite ? "red" : "black" }}
                    className="fav-icon"
                    onClick={() => {
                        handleFav(product)
                    }}
                >
                    {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>

                <Link to={`/productDetails/${product.id}`} className="product-link">
                    <img className="product-image" src={product.image} alt={product.name} />
                    <div className="product-info">
                        <h5 className="product-name">{product.name}</h5>
                        <Rating name="size-medium" defaultValue={0} value={product.rating} readOnly />
                        {/* </div>
                    <div style={{ marginTop: "5px", color: "gold" }}> */}
                    </div>
                    <div className="product-pricing">
                        <p className="special-price">₹{product.special_price}</p>
                        {product.price && <p className="original-price">${product.price}</p>}
                        {product.price && <p className="discount">{Math.round(((product.price - product.special_price) / product.price) * 100)}% off</p>}
                    </div>
                </Link>

                <Button
                    variant="contained"
                    style={{ backgroundColor: "#00AFCA" }}
                    className="add-to-cart"
                    onClick={() => {
                        handleClick(product);
                    }}
                >
                    Add to Cart
                </Button>
            </Card>

            <Snackbar
                open={snack}
                autoHideDuration={3000}
                onClose={() => setSnack(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert
                    onClose={() => setSnack(false)}
                    severity="info"
                    // variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>

    )
}

export default ProductCard;