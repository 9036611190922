import { useLocation } from 'react-router-dom';
import { Container, Paper, Typography, Divider, Button, List, ListItem, ListItemText, Avatar, Step, StepLabel, Stepper } from '@mui/material';
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';
import { format } from 'date-fns';

interface Status {
    status: string;
    date: string;
}

const OrderDetails = () => {
    const location = useLocation();
    // console.log("location :: ", location);

    const orderDetails = location.state.order_details;
    console.log("orderDetails ::: ", orderDetails);

    const steps = ['RECEIVED', 'PROCESSED', 'SHIPPED', 'DELIVERED'];

    const getStatusDate = (orderStatus: Status[], step: string): string | null => {
        const statusEntry = orderStatus.find((s: Status) => s.status.toUpperCase() === step);
        return statusEntry ? statusEntry.date : null;
    };

    const getActiveStep = (orderStatus: Status[]): number => {
        for (let i = steps.length - 1; i >= 0; i--) {
            if (orderStatus.some(s => s.status.toUpperCase() === steps[i])) {
                return i;
            }
        }
        return -1;
    };

    return (
        <Container style={{ marginBottom: "20px" }}>
            <Paper elevation={3} sx={{ padding: 4, marginTop: 3 }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <Typography gutterBottom style={{ fontWeight: "600" }}>Order Detail</Typography>
                        <Typography variant="body2"><strong>Order ID:</strong> {orderDetails.id}</Typography>
                        <Typography variant="body2"><strong>Placed On:</strong> {format(new Date(orderDetails.date_added), 'dd-MM-yyyy h:mm a')}</Typography>

                    </div>
                    <div>
                        <Typography gutterBottom style={{ fontWeight: "600" }}>Shipping Details</Typography>
                        <Typography variant="subtitle2">{orderDetails.username}</Typography>
                        <Typography variant="body2">{orderDetails.address}</Typography>
                        <Typography variant="body2" marginTop={"2px"}><strong>Mobile Number:</strong></Typography>
                        <Typography variant="body2">{orderDetails.mobile}</Typography>
                    </div>
                    <div>
                        <Typography gutterBottom style={{ fontWeight: "600" }}>More Action</Typography>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <FileDownloadSharpIcon />
                            <Typography variant="body2">Download Invoice</Typography>
                        </div>
                        <Button variant="contained" style={{ backgroundColor: "#00AFCA" }} >Invoice</Button>
                    </div>
                </div>

                <Divider sx={{ my: 3 }} />
                <Typography gutterBottom style={{ fontWeight: "600" }}>Order Tracking</Typography>
                <Typography variant="body2">Tracking URL : {orderDetails.url}</Typography>
                <Divider sx={{ my: 3 }} />

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <List>
                            {orderDetails.order_items.map((item: any, index: any) => (
                                <ListItem key={index} sx={{ my: 1 }}>
                                    <Avatar src={item.image} variant="rounded" sx={{ width: 56, height: 56, mr: 2 }} />
                                    <ListItemText primary={item.name} secondary={`Quantity: ${item.quantity}`} />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <div>
                        <Typography style={{ fontWeight: "600" }} gutterBottom>Price Details</Typography>
                        <Typography>Total Order Price: ₹{orderDetails.total_payable}</Typography>
                        <Typography>Delivery Charge: ₹{orderDetails.delivery_charge}</Typography>
                        <Typography style={{ fontWeight: "600" }}>Final Total: ₹{orderDetails.final_total}</Typography>
                    </div>
                </div>

                <Divider sx={{ my: 3 }} />
                <Stepper activeStep={getActiveStep(orderDetails.status)} alternativeLabel>
                    {steps.map((label) => (

                        <Step key={label}>
                            <StepLabel>
                                <Typography variant="h6" fontSize={"medium"} fontWeight="bold">
                                    {label}
                                </Typography>
                                {getStatusDate(orderDetails.status, label)}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {/* <Button variant="contained" color="secondary" sx={{ mt: 2 }}>Verification Pending</Button> */}
            </Paper>
        </Container>
    )
}

export default OrderDetails;