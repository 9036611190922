import React, { useEffect, useState } from 'react'
import { Container, Box, Typography, Grid, TextField, IconButton, Button, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postRequest } from '../Api/api';

const ViewCart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);

    const [cartItems, setCartItems] = React.useState(products);
    // const total = cartItems.reduce((sum, item) => sum + item.price, 0);

    const handleRemoveItem = (id: any) => {
        setCartItems(cartItems.filter((item: any) => item.id !== id));
    };

    const fetchCart = async () => {
        const response = await postRequest("get_user_cart", navigate, dispatch);
        console.log("fetchCart :: response :: ", response);
        setProducts(response.data);
        setTotal(response.sub_total);
    }

    useEffect(() => {
        fetchCart();
        // eslint-disable-next-line
    }, []);

    return (
        <Container sx={{ marginBottom: "20px" }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4, mb: 4, background: "#00AFCA", padding: "20px", borderRadius: "15px" }}>
                <Box sx={{ textAlign: 'center', p: 2, color: 'white', mx: 1 }}>
                    <Typography variant="h6" fontSize={"x-large"} style={{ borderBottom: "2px solid white" }}>Shopping Cart</Typography>
                </Box>
                <Typography variant="h6" sx={{ mx: 2, color: 'white' }}>→</Typography>
                <Box onClick={() => { navigate("/checkout") }} sx={{ textAlign: 'center', p: 2, color: 'white', mx: 1 }}>
                    <Typography variant="h6" fontSize={"x-large"} style={{ cursor: "pointer" }}>Checkout</Typography>
                </Box>
                <Typography variant="h6" sx={{ mx: 2, color: 'white' }}>→</Typography>
                <Box sx={{ textAlign: 'center', p: 2, color: 'white', mx: 1 }}>
                    <Typography variant="h6" fontSize={"x-large"}>Order Complete</Typography>
                </Box>
            </Box>
            <Grid container spacing={3}>

                <Grid item xs={12} md={8}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell style={{ fontSize: "medium", fontWeight: "600" }}>Product</TableCell>
                                    <TableCell style={{ fontSize: "medium", fontWeight: "600" }}>Price</TableCell>
                                    <TableCell style={{ fontSize: "medium", fontWeight: "600" }}>Quantity</TableCell>
                                    <TableCell style={{ fontSize: "medium", fontWeight: "600" }}>Subtotal</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {products.map((item: any) => (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            <IconButton onClick={() => handleRemoveItem(item.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={item.product_details[0].image} alt={item.product_details[0].name} style={{ width: 100, marginRight: 16 }} />
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box>
                                                <Typography variant="body1">{item.product_details[0].name}</Typography>
                                                <Typography variant="subtitle1" color="text.secondary">{item.product_details[0].short_description}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>₹{item.net_amount}</TableCell>
                                        <TableCell>
                                            <TextField type="number" defaultValue={1} size="small" sx={{ width: 60 }} />
                                        </TableCell>
                                        <TableCell>₹{item.net_amount * 1}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6" fontWeight={600}>Cart Totals</Typography>
                        <Divider sx={{ my: 2 }} />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="h5" fontSize={"large"}>
                                Total
                            </Typography>
                            <Typography variant="h5" fontSize={"large"}>
                                ₹{total}
                            </Typography>
                        </div>
                        <Button variant="contained" style={{ backgroundColor: '#00AFCA', color: 'white' }} fullWidth sx={{ mt: 2 }} onClick={() => { navigate("/checkout") }}>
                            Proceed to checkout
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ViewCart;