import { Container, Box, Typography, Divider, Grid, ListItemIcon, Paper, Button } from '@mui/material';
import UserSidebar from '../components/UserSidebar';
import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { postRequest } from '../Api/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

interface Status {
    status: string;
    date: string;
}

// interface Order {
//     id: number;
//     date_added: string;
//     status: Status[];
//     order_items: {
//         id: number;
//         name: string;
//         price: string;
//         image: string;
//         active_status: string;
//     }[];
// }

const Orders: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [orders, setOrders] = useState([]);

    // const [status, setStatus] = useState([]);
    // const [orders, setOrders] = useState<Order[]>([]);

    const fetchOrder = async () => {
        try {
            const response = await postRequest("get_orders", navigate, dispatch);
            console.log("orders :: response :: ", response);
            setOrders(response.data);
        } catch (error) {
            console.log("fetchOrder :: errror :: ", error);
        }
    }

    const steps = ['RECEIVED', 'PROCESSED', 'SHIPPED', 'DELIVERED'];

    const getStatusDate = (orderStatus: Status[], step: string): string | null => {
        const statusEntry = orderStatus.find((s: Status) => s.status.toUpperCase() === step);
        return statusEntry ? statusEntry.date : null;
    };

    const getActiveStep = (orderStatus: Status[]): number => {
        for (let i = steps.length - 1; i >= 0; i--) {
            if (orderStatus.some(s => s.status.toUpperCase() === steps[i])) {
                return i;
            }
        }
        return -1;
    };

    const handleClick = (e: any) => {
        try {
            console.log("handleClick :: e :: ", e);
            navigate(`/order-details/${e.id}`, { state: { order_details: e } });
        } catch (error) {
            console.log("handleCLick  :: error :: ", error);
        }
    }

    useEffect(() => {
        fetchOrder();
        // eslint-disable-next-line
    }, []);

    return (
        <Container maxWidth="lg" sx={{ fontFamily: 'Poppins, sans-serif' }}>
            <Box display="flex" mt={3}>
                <UserSidebar />
                <Box sx={{ flexGrow: 1, p: 2 }}>
                    <Typography variant="h5" sx={{ fontFamily: 'Poppins, sans-serif' }} gutterBottom style={{ paddingTop: "10px", fontWeight: "600" }}>
                        Orders
                    </Typography>
                    <Grid item xs={9}>
                        {orders.map((order: any) => (
                            <React.Fragment key={order.id}>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                                    <Typography variant="h5" sx={{ fontFamily: 'Poppins, sans-serif' }} style={{ fontSize: "large" }}>Order ID: {order.id}</Typography>
                                    <Typography variant="h5" style={{ fontSize: "large" }}>Total: ₹{order.total}</Typography>
                                </div>

                                {order.order_items.map((item: any) => (
                                    <Paper style={{ padding: 16, marginBottom: 16, boxShadow: "none" }} key={item.id}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}>
                                                <img src={item.image} alt={item.name} style={{ width: '100%' }} />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography variant="h6" fontWeight={600} sx={{ fontFamily: 'Poppins, sans-serif' }}>{item.name}</Typography>
                                                <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>Quantity : {item.quantity}</Typography>
                                                <Typography variant='body2'>₹{item.price}</Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{ textAlign: 'center' }}>
                                                <ListItemIcon>
                                                    <CheckCircleIcon style={{ color: "#00AFCA" }} />
                                                </ListItemIcon>
                                                <Typography variant="body2" sx={{ fontFamily: 'Poppins, sans-serif' }}>{item.active_status}</Typography>
                                                <Typography variant="body2" sx={{ fontFamily: 'Poppins, sans-serif' }}>{format(new Date(order.date_added), 'dd-MM-yyyy h:mm a')}</Typography>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingTop: "10px"
                                                }}>
                                                    <Button variant="outlined"
                                                        style={{
                                                            minWidth: "135px",
                                                            marginRight: "5px", color: "#00AFCA", borderColor: "#00AFCA"
                                                        }}
                                                        onClick={() => handleClick(order)}
                                                    >
                                                        View Details
                                                    </Button>
                                                    <Button variant="contained" style={{ minWidth: "135px", backgroundColor: "#00AFCA" }}>Reorder</Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                ))}

                                <Box sx={{ width: '100%' }}>
                                    <Stepper activeStep={getActiveStep(order.status)} alternativeLabel>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>
                                                    {/* {label}
                                                    <br />
                                                    {getStatusDate(order.status, label)} */}

                                                    <Typography variant="h6" fontSize={"medium"} fontWeight="bold">
                                                        {label}
                                                    </Typography>
                                                    {getStatusDate(order.status, label)}
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Box>

                                <Divider style={{ margin: '24px 0' }} />
                            </React.Fragment>
                        ))}
                    </Grid>

                </Box>
            </Box>
        </Container>
    )
}

export default Orders;