import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Grid, TextField, FormControl, InputLabel, MenuItem, Select,
    Dialog, Button, FormControlLabel, RadioGroup, Radio, FormLabel
} from '@mui/material';
import { postRequest } from '../Api/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

interface AddressFormProps {
    open: boolean;
    onClose: () => void;
    onSave: (formData: any) => void;
    initialFormData?: any;
}

const AddressForm: React.FC<AddressFormProps> = ({ open, onClose, onSave, initialFormData }) => {
    console.log(" initialFormData :: ", initialFormData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cities, setCities] = useState([]);
    const [pincodes, setPincodes] = useState([]);
    const [cityId, setCityId] = useState('');
    const [pincodeId, setPincodeId] = useState('');

    const fetchCity = async () => {
        try {
            const details = await postRequest("get_cities", navigate, dispatch);
            console.log("fetchCity :: details :: ", details);
            setCities(details.data);
        } catch (error) {
            console.log("fetchCity ::  error :: ", error);
        }
    }

    const fetchPincode = async (cityId: any) => {
        try {
            console.log("fetchPincode :: cityId :: ", cityId);
            let data = {};
            if (cityId) {
                data = { id: cityId }
            }

            const details = await postRequest("get_zipcode_by_city_id", navigate, dispatch, data);
            console.log("fetchPincode :: details :: ", details);
            setPincodes(details.data);
        } catch (error) {
            console.log("fetchPincode ::  error :: ", error);
        }
    }

    const [formData, setFormData] = useState(initialFormData || {
        name: '',
        mobile: '',
        alternate_mobile: '',
        address: '',
        country: '',
        state: '',
        city: '',
        area: '',
        pincode: '',
        type: 'home',
    });

    useEffect(() => {
        if (initialFormData) {
            setFormData(initialFormData);
        }
        fetchCity();
        fetchPincode(null);
        // eslint-disable-next-line
    }, [initialFormData]);

    useEffect(() => {
        if (cityId) {
            fetchPincode(cityId);
        }
        // eslint-disable-next-line
    }, [cityId]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === "city") {
            const selectedCity: any = cities.find((city: any) => city.name === value);
            if (selectedCity) {
                setCityId(selectedCity.id);
            }
        }
        if (name === "pincode") {
            const selectedPincode: any = pincodes.find((e: any) => e.zipcode === value);
            if (selectedPincode) {
                setPincodeId(selectedPincode.id);
            }
        }

        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        onSave({ ...formData, city_id: cityId, pincode_name: pincodeId, "system_pincode": "0" });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <Box sx={{ p: 6 }}>
                <Typography variant="h5" style={{ textAlign: "center", marginBottom: "10px", fontWeight: "600" }}>{initialFormData ? "Update Address" : "Add Address"}</Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Name" name="name" value={formData.name} onChange={handleChange} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Mobile Number" name="mobile" value={formData.mobile} onChange={handleChange} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Alternate Mobile" name="alternate_mobile" value={formData.alternate_mobile} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Address" name="address" value={formData.address} onChange={handleChange} required multiline rows={4} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Country" name="country" value={formData.country} onChange={handleChange} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="State" name="state" value={formData.state} onChange={handleChange} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required>
                                <InputLabel id="city-label">City</InputLabel>
                                <Select
                                    labelId="city-label"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    label="City"
                                >
                                    {cities.map((city: any, index) => (
                                        <MenuItem key={index} value={city.name}>
                                            {city.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Area" name="area" value={formData.area} onChange={handleChange} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required>
                                <InputLabel id="pincode-label">Pincode</InputLabel>
                                <Select
                                    labelId="pincode-label"
                                    name="pincode"
                                    value={formData.pincode}
                                    onChange={handleChange}
                                    label="Pincode"
                                >
                                    {pincodes.map((pincode: any, index) => (
                                        <MenuItem key={index} value={pincode.zipcode}>
                                            {pincode.zipcode}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Type</FormLabel>
                                <RadioGroup row name="type" value={formData.type} onChange={handleChange}>
                                    <FormControlLabel value="home" control={<Radio />} label="Home" />
                                    <FormControlLabel value="office" control={<Radio />} label="Office" />
                                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" type="submit" style={{ backgroundColor: "#00AFCA" }}>
                                Save Address
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Dialog>
    );
}

export default AddressForm;