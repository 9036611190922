import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    error: null,
    loading: false,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        signInSuccess: (state, action) => {
            console.log("setUserDetails", action.payload);
            state.user = action.payload.data;
            state.loading = false;
            state.error = null;
        },
        signInStart: (state) => {
            state.loading = true;
        },
        signOutSuccess: (state) => {
            state.user = null;
            state.loading = false;
            state.error = null;
        },
        signInFailure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    }
})

export const { signInSuccess, signInStart, signOutSuccess, signInFailure } = userSlice.actions;

export default userSlice.reducer;