import { Container, Box, Typography, Button } from '@mui/material';
import UserSidebar from '../components/UserSidebar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import referImg from "../assets/referral.webp";

function ReferEarn() {
    const referralCode = 'vXaEvNuR';

    const handleCopy = () => {
        navigator.clipboard.writeText(referralCode);
        alert('Referral code copied to clipboard!');
    };

    return (
        <Container maxWidth="lg">
            <Box display="flex" mt={3}>
                <UserSidebar />

                <Box sx={{ flexGrow: 1, p: 2 }}>
                    <Typography variant="h5" gutterBottom style={{ paddingTop: "10px", fontWeight: "600" }}>
                        Refer & earn
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        // height="70vh"
                        textAlign="center"
                    >
                        <Box
                            component="img"
                            sx={{
                                margin: "10px",
                                height: 250,
                                width: 250,
                                maxHeight: { xs: 180, md: 180 },
                                maxWidth: { xs: 180, md: 180 },
                                marginBottom: 2
                            }}
                            alt="Refer a Friend"
                            src={referImg}
                        />
                        <Typography variant="h6" fontWeight={600} gutterBottom>
                            Your Referral Code
                        </Typography>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            border={1}
                            borderColor="grey.400"
                            borderRadius={1}
                            padding={1}
                            marginBottom={2}
                            width={250}
                        >
                            <Typography variant="h5">
                                {referralCode}
                            </Typography>

                        </Box>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#00AFCA", marginBottom: "10px" }}
                            startIcon={<ContentCopyIcon />}
                            onClick={handleCopy}
                        >
                            Tap to copy
                        </Button>
                        <Typography variant="body1">
                            Invite your friends to join and get the reward as soon as your friend places his first order.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default ReferEarn;